import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Variables } from '../Helper';
import InputIncremental from '../InputIncremental';
import { Filter } from './FilterContainer';
import translate from '../../components/languages';
import './ModalCustomFilters.scss';
import _default from 'react-bootstrap/esm/CardColumns';
import { AMENITIES_LIST } from '../amenities/amenities';

export interface CustomFilter {
  bathrooms: number;
  bedrooms: number;
  amenities: String[];
  propertyTypes: String[];
}

interface ModalProps {
  isVisible: boolean;
  onOk: (params: CustomFilter) => void;
  toggleVisible: (params: boolean) => void;
  filters?: Filter;
  refreshPage: () => string | null;
}

interface FilterCheck {
  name: string;
  status: boolean;
}

const ModalFilter = ({
  isVisible,
  onOk,
  toggleVisible,
  filters,
  refreshPage,
}: ModalProps) => {
  const [bathrooms, setBathrooms] = useState<number>(
    filters && filters.minimumBathrooms ? filters.minimumBathrooms : 0,
  );
  const [bedrooms, setBethrooms] = useState<number>(
    filters && filters.minimumBedrooms ? filters.minimumBedrooms : 0,
  );
  const [amenities, setAmentieiesFilters] = useState<String[]>(
    filters && filters.customAmenities
      ? filters.customAmenities.split(',')
      : [],
  );
  const [propertyTypes, setTypePropertiesFilter] = useState<String[]>(
    filters && filters.typeProperties ? filters.typeProperties.split(',') : [],
  );

  const [toggleWifi, setToggleWifi] = useState(false);
  const [toggleAC, setToggleAC] = useState(false);
  const [togglePool, setTogglePool] = useState(false);
  const [toggleBBQ, setToggleBBQ] = useState(false);
  const [toggleGym, setToggleGym] = useState(false);
  const [toggleJacuzzi, setToggleJacuzzi] = useState(false);
  const [toggleLaundry, setToggleLaundry] = useState(false);
  const [toggleWasher, setToggleWasher] = useState(false);

  useEffect(() => {
    let auxArray =
      filters && filters?.customAmenities?.split(',')?.length > 0
        ? filters?.customAmenities?.split(',')
        : [];
    auxArray = auxArray.filter((elem) => elem !== '');
    setAmentieiesFilters(auxArray);
  }, [filters]);

  function toggleFilter(
    arrayString: String[],
    filter: FilterCheck,
    setterHook: Function,
  ) {
    let existInArray = arrayString.filter((each) => each === filter.name);
    const auxAmenities = amenities.filter((a) => a !== filter.name);
    console.log('>>>amenities le paso', auxAmenities);

    if (existInArray.length && filter.status === false) {
      setterHook(arrayString.filter((each) => each !== filter.name));

      // toggles(filter.name)
    } else if (!existInArray.length) {
      let newArray = arrayString.concat(filter.name);
      setterHook(newArray);
    }
  }

  return (
    <>
      <div className={`contenedor ${isVisible ? 'activo' : ''}`}>
        <div className="cont-d-flex p-2">
          <div className="" style={{ width: '362px' }}>
            <span className="title-texto">
              {translate.t('modal-filter-feature-title').toString()}
            </span>
            <div className="cont-d-block">
              <div className="filter-block" style={{ padding: '0px 10px' }}>
                <div
                  className="custom-filter-checkbox-container"
                  onClick={() => {
                    setToggleWifi(!toggleWifi);
                    toggleFilter(
                      amenities,
                      {
                        name: AMENITIES_LIST.HAS_INTERNET_WIFI,
                        status: toggleWifi,
                      },
                      setAmentieiesFilters,
                    );
                  }}
                >
                  <div
                    className="custom-filter-checkbox"
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_INTERNET_WIFI)
                        ? {
                            border: '2px solid #009E8A',
                            backgroundColor: '#079883',
                          }
                        : {
                            backgroundColor: 'transparent',
                            border: '1px solid lightgrey',
                          }
                    }
                  ></div>
                  <span
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_INTERNET_WIFI)
                        ? { color: '#009E8A', fontWeight: 'bold' }
                        : { color: 'grey' }
                    }
                  >
                    Wifi
                  </span>
                </div>

                <div
                  className="custom-filter-checkbox-container"
                  onClick={() => {
                    setToggleAC(!toggleAC);
                    toggleFilter(
                      amenities,
                      {
                        name: AMENITIES_LIST.HAS_AIR_CONDITIONING,
                        status: toggleAC,
                      },
                      setAmentieiesFilters,
                    );
                  }}
                >
                  <div
                    className="custom-filter-checkbox"
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_AIR_CONDITIONING)
                        ? {
                            border: '2px solid #009E8A',
                            backgroundColor: '#079883',
                          }
                        : {
                            backgroundColor: 'transparent',
                            border: '1px solid lightgrey',
                          }
                    }
                  ></div>
                  <span
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_AIR_CONDITIONING)
                        ? { color: '#009E8A' }
                        : { color: 'grey' }
                    }
                  >
                    Aire Acondicionado
                  </span>
                </div>

                <div
                  className="custom-filter-checkbox-container"
                  onClick={() => {
                    setTogglePool(!togglePool);
                    toggleFilter(
                      amenities,
                      { name: AMENITIES_LIST.HAS_POOL, status: togglePool },
                      setAmentieiesFilters,
                    );
                  }}
                >
                  <div
                    className="custom-filter-checkbox"
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_POOL)
                        ? {
                            border: '2px solid #009E8A',
                            backgroundColor: '#079883',
                          }
                        : {
                            backgroundColor: 'transparent',
                            border: '1px solid lightgrey',
                          }
                    }
                  ></div>
                  <span
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_POOL)
                        ? { color: '#33B5A1' }
                        : { color: 'grey' }
                    }
                  >
                    Piscina
                  </span>
                </div>
              </div>
              <div className="filter-block">
                <div
                  className="custom-filter-checkbox-container"
                  onClick={() => {
                    setToggleBBQ(!toggleBBQ);
                    toggleFilter(
                      amenities,
                      { name: AMENITIES_LIST.HAS_BARBECUE, status: toggleBBQ },
                      setAmentieiesFilters,
                    );
                  }}
                >
                  <div
                    className="custom-filter-checkbox"
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_BARBECUE)
                        ? {
                            border: '2px solid #33B5A1',
                            backgroundColor: '#079883',
                          }
                        : {
                            backgroundColor: 'transparent',
                            border: '1px solid lightgrey',
                          }
                    }
                  ></div>
                  <span
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_BARBECUE)
                        ? { color: '#33B5A1' }
                        : { color: 'grey' }
                    }
                  >
                    Parrilla
                  </span>
                </div>

                <div
                  className="custom-filter-checkbox-container"
                  onClick={() => {
                    setToggleGym(!toggleGym);
                    toggleFilter(
                      amenities,
                      { name: AMENITIES_LIST.HAS_GYM, status: toggleGym },
                      setAmentieiesFilters,
                    );
                  }}
                >
                  <div
                    className="custom-filter-checkbox"
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_GYM)
                        ? {
                            border: '2px solid #33B5A1',
                            backgroundColor: '#079883',
                          }
                        : {
                            backgroundColor: 'transparent',
                            border: '1px solid lightgrey',
                          }
                    }
                  ></div>
                  <span
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_GYM)
                        ? { color: '#009E8A' }
                        : { color: 'grey' }
                    }
                  >
                    Gym
                  </span>
                </div>

                <div
                  className="custom-filter-checkbox-container"
                  onClick={() => {
                    setToggleJacuzzi(!toggleJacuzzi);
                    toggleFilter(
                      amenities,
                      {
                        name: AMENITIES_LIST.HAS_JACUZZI,
                        status: toggleJacuzzi,
                      },
                      setAmentieiesFilters,
                    );
                  }}
                >
                  <div
                    className="custom-filter-checkbox"
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_JACUZZI)
                        ? {
                            border: '2px solid #009E8A',
                            backgroundColor: '#079883',
                          }
                        : {
                            backgroundColor: 'transparent',
                            border: '1px solid lightgrey',
                          }
                    }
                  ></div>
                  <span
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_JACUZZI)
                        ? { color: '#009E8A' }
                        : { color: 'grey' }
                    }
                  >
                    Jacuzzi
                  </span>
                </div>
              </div>
              <div className="filter-block">
                <div
                  className="custom-filter-checkbox-container"
                  onClick={() => {
                    setToggleLaundry(!toggleLaundry);
                    toggleFilter(
                      amenities,
                      {
                        name: AMENITIES_LIST.HAS_LAUNDROMAT_NEARBY,
                        status: toggleLaundry,
                      },
                      setAmentieiesFilters,
                    );
                  }}
                >
                  <div
                    className="custom-filter-checkbox"
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_LAUNDROMAT_NEARBY)
                        ? {
                            border: '2px solid #009E8A',
                            backgroundColor: '#079883',
                          }
                        : {
                            backgroundColor: 'transparent',
                            border: '1px solid lightgrey',
                          }
                    }
                  ></div>
                  <span
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_LAUNDROMAT_NEARBY)
                        ? { color: '#009E8A' }
                        : { color: 'grey' }
                    }
                  >
                    Laundry
                  </span>
                </div>

                <div
                  className="custom-filter-checkbox-container"
                  onClick={() => {
                    setToggleWasher(!toggleWasher);
                    toggleFilter(
                      amenities,
                      { name: AMENITIES_LIST.HAS_WASHER, status: toggleWasher },
                      setAmentieiesFilters,
                    );
                  }}
                >
                  <div
                    className="custom-filter-checkbox"
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_WASHER)
                        ? {
                            border: '2px solid #009E8A',
                            backgroundColor: '#079883',
                          }
                        : {
                            backgroundColor: 'transparent',
                            border: '1px solid lightgrey',
                          }
                    }
                  ></div>
                  <span
                    style={
                      amenities.includes(AMENITIES_LIST.HAS_WASHER)
                        ? { color: '#009E8A' }
                        : { color: 'grey' }
                    }
                  >
                    Lavarropas
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='col-md-3'>
                            <span className='title-texto'>{translate.t('modal-filter-type-place-title').toString()}</span>
                            {Variables.propertiesTypes.map((each, k) => {

                                return (
                                    <div key={k} className="col-md-6">
                                        <Form.Check
                                            onChange={(e) => toggleFilter(propertyTypes, { name: e.target.value, status: e.target.checked }, setTypePropertiesFilter)}
                                            label={each.name}
                                            value={each.value}
                                            checked={propertyTypes.includes(each.value)}
                                            className="text-filter" custom={true} />
                                    </div>
                                )
                            }
                            )}
                        </div> */}
          <div className="filters-cont-two">
            <span className="title-texto">
              {translate.t('modal-filter-conditions-title').toString()}
            </span>
            <div className="">
              <InputIncremental
                title={'Bathrooms'}
                value={bathrooms}
                setData={setBathrooms}
              />
              <InputIncremental
                title={'Bedrooms'}
                value={bedrooms}
                setData={setBethrooms}
              />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <button
              onClick={() =>
                onOk({
                  bathrooms: bathrooms,
                  bedrooms: bedrooms,
                  amenities,
                  propertyTypes,
                })
              }
              className="btnW2ba-search-new"
            >
              {translate.t('confirm').toString()}
            </button>
            <button onClick={() => refreshPage()} className="btnW2ba-clear-new">
              {translate.t('propertyList.clearFilter').toString()}
            </button>
          </div>
        </div>
      </div>
      {/* <Modal size="lg" show={isVisible} onHide={() => toggleVisible(false)} aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {translate.t('modal-filter-title').toString()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                

            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => onOk({ bathrooms, bedrooms, amenities, propertyTypes })} className="btnW2ba-search">
                    {translate.t('confirm').toString()}
                </button>
            </Modal.Footer>
        </Modal > */}
    </>
  );
};

export { ModalFilter };
