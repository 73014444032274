import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type toastType = 'success' | 'warning' | 'error';
type toastTheme = 'light' | 'dark';

export const ToastConfig = (message : string, type? : toastType, theme? : toastTheme) => {
  switch (type) {
    case "success":
      return toast.success(message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: theme
      }); 

    case "warning":
      return toast.warning(message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: theme
      });

    case "error":
      return toast.error(message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: theme
      });
  }
};
