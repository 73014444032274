import moment from 'moment';

const Helper = {
  // getters de info
  dateDiff: function (from, to) {
    var date1 = moment(from);
    var date2 = moment(to);
    return date2.diff(date1, 'days');
  },
  getParamNew: function (paramName, locationParams) {
    const urlParam = new URLSearchParams(locationParams.search).get(paramName);
    const decodedParam = decodeURIComponent(urlParam);

    return decodedParam;
  },
  getParam: function (name, location, emptyValue = null) {
    if (location === undefined || location.search === undefined) return null;
    var params = location.search.split('&');

    if (params) {
      let filteredParam = params.filter((eachParam) => {
        if (eachParam.indexOf(name, 0) !== -1) {
          return eachParam.split(`${name}=`);
        }
      });
      return filteredParam[0] !== undefined
        ? filteredParam[0].split(`${name}=`)[1]
        : emptyValue;
    }
    return location.search.split(`${name}=`);
  },
  filterTrueAmenities: function (amenities) {
    return amenities.filter((each) => each[1]);
  },
  changeLanguage: function (val) {
    localStorage.setItem('country', val);
    window.location.reload();
  },
  getLanguage: function () {
    return localStorage.getItem('country') || Helper.LANGUAGE_VARS.SPANISH;
  },
  languageEnable: function (val) {
    return localStorage.getItem('country') === val;
  },
  prepareLocation: function (val) {
    return { latitude: val.latitude, longitude: val.longitude };
  },
  prepareLocOwnServer: function (val) {
    return {
      latitude: val?.address.latitude,
      longitude: val?.address.longitude,
    };
  },

  parsePropertyListWithDescription: function (arrayList) {
    let out = [];
    arrayList.map((each, i) => {
      if (i === 0 || i % 2 === 0) {
        out.push({ property: each, propertyDescription: null });
      } else {
        out[out.length - 1].propertyDescription = each;
      }
    });
    return out;
  },
  LANGUAGE_VARS: { SPANISH: 'es', ENGLISH: 'en' },
  filterPropertiesByParamsFrontEnd: function (properties, params, cantOfDays) {
    return properties.filter((eachProperty) => {
      let property = eachProperty.property;
      let typesAllowed = params.typeProperties
        ? params.typeProperties.toLowerCase().split(',')
        : 0;
      if (
        typesAllowed.length &&
        !typesAllowed.includes(property.type.toLowerCase())
      )
        return false;
      if (
        params.minimumBathrooms &&
        params.minimumBathrooms > parseInt(property.bathrooms)
      )
        return false;
      if (property.externalID === 'hide-in-web') return false;
      if (
        cantOfDays &&
        property.minimumStay &&
        property.minimumStay > cantOfDays
      )
        return false;

      return true;
    });
  },
  showBedTypologies: function (bedTypes) {
    const array = Object.entries(bedTypes);
    let txtToShow = '';
    array.forEach(([key, value]) => {
      if (value >= 1 && key !== 'roomNumber') {
        txtToShow += `${this.splitCamelCase(key).replace('count', '')}:${value}
                `;
      }
    });

    return txtToShow;
  },
  splitCamelCase: function (input) {
    const regex = /(?<=[a-z])(?=[A-Z])/g;
    return input.replace(regex, ' ').toLocaleLowerCase();
  },
};

const Variables = {
  limitMax: 10,
  regularLimit: 10,
  guestList: [1, 2, 3, 4, 5, 6, 7],
  bedRoomList: [1, 2, 3, 4, 5],
  amenitiesList: [
    { name: 'Wifi', value: 'hasInternetWifi' },
    { name: 'Aire Acondicionado', value: 'hasAirConditioning' },
    { name: 'Piscina', value: 'hasPool' },
    { name: 'Parrilla', value: 'hasBarbecue' },
    { name: 'Gym', value: 'hasGym' },
    { name: 'Jacuzzy', value: 'hasJacuzzi' },
    { name: 'Laundry', value: 'hasLaundry' },
    { name: 'Lavarropas', value: 'hasWasher' },
  ],
  propertiesTypes: [
    { name: 'Apartment', value: 'Apartment' },
    { name: 'Duplex', value: 'Duplex' },
    { name: 'Studio', value: 'Studio' },
    { name: 'House', value: 'House' },
    { name: 'Condo', value: 'Condo' },
  ],
  neighboorList: [
    { name: 'Belgrano, Argentina', value: 'Belgrano' },
    { name: 'San Telmo, Argentina', value: 'San Telmo' },
    { name: 'CABA, Argentina', value: 'CABA' },
    { name: 'Las Cañitas, Argentina', value: 'Las Cañitas' },
    { name: 'Puerto Madero, Argentina', value: 'Puerto Madero' },
    { name: 'Palermo, Argentina', value: 'Palermo' },
    { name: 'Recoleta, Argentina', value: 'Recoleta' },
    { name: 'Centro, Argentina', value: 'Centro' },
  ],
  localStorageFavorite: 'favorites',
  hostfullyDateFormat: 'YYYY-MM-DD',
};

export default Helper;
export { Variables };
