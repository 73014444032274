import { useEffect, useState } from 'react';
import Helper, { Variables } from '../Helper';
import moment from 'moment';
import API from '../API';

type TParamsUseQuote = {
  uid: string;
  guestNumber?: number;
  checkIn?: string;
  checkOut?: string;
};

type TQuoteHostfully = {
  amount: number;
  amountToPay: number;
  cleaningFeeAmount: number;
  currency: string;
  customFees: any;
  discount: any;
  duration: number;
  extraGuestFeeTaxes: number;
  extraGuestsFeeAmount: number;
  property: any;
  rent: number;
  rentTaxes: number;
  securityDepositAmount: number;
  taxationRate: number;
  totalWithTaxes: number;
  totalWithTaxesAndSecDeposit: number;
  totalWithoutTaxes: number;
  dailyPrice: number;
};

export default function useQuote(filters: TParamsUseQuote) {
  const [quote, setQuote] = useState<TQuoteHostfully>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (!filters.checkIn || !filters.checkOut) {
      filters.checkIn = moment().format(Variables.hostfullyDateFormat);
      filters.checkOut = moment()
        .add(30, 'days')
        .format(Variables.hostfullyDateFormat);
    }
    const daysBetweenDates = Helper.dateDiff(filters.checkIn, filters.checkOut);

    const params = {
      propertyUid: filters.uid,
      guestNumber: filters.guestNumber || 1,
      checkInDate: filters.checkIn,
      checkOutDate: filters.checkOut,
    };

    // console.log('trying to get price from:', params);

    const checkerIds = [
      'a0fa5f69-11a5-4343-b032-d16db2d04412',
      '135046dc-c684-4d0a-b1e2-39bc4c9a4775',
      'd945c8c3-4f61-4d53-9502-41b7674260b6',
    ];
    API.Web.getBookQuote(params, filters.uid).then((quote) => {
      const final = {
        ...quote,
        dailyPrice: Math.round(quote.totalWithoutTaxes / daysBetweenDates),
      };

      if (checkerIds.includes(filters.uid)) {
        console.log('front checer', filters.uid, quote, final);
      }

      setLoading(false);
      setQuote(final);
    });
  }, [filters.uid, filters.checkIn, filters.checkOut, filters.guestNumber]);

  return { quote, loading };
}
