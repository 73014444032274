import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Helper, { Variables } from '../Helper';
import translate from '../../components/languages';
import '../../components/componentStyles/HomeStyle.scss';
import { CustomFilter, ModalFilter } from './ModalCustomFilters';
import blackHeart from '../../assets/icons/heart-black-border.png';
import redHeart from '../../assets/icons/redHeart.svg';
import startOn from '../../assets/icons/star_on.png';
import startOff from '../../assets/icons/star_off.png';
//date range calendar
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
import moment from 'moment';
import { PickerHandle } from 'rsuite';
import lupa from '../../assets/icons/lupa.svg';
import filterIcon from '../../assets/icons/filterIcon.svg';
import closeFilterIcon from '../../assets/icons/closeFilterIcon_1.svg';
import Select, { components } from 'react-select';
import pin from '../../assets/icons/pinMap.svg';
import guests from '../../assets/icons/guests.svg';
import blackArrow from '../../assets/icons/blackArrow.svg';
import { AmenityKey, HELPER_TSX } from '../HelperTSX';
import useNeighborhoods from '../hooks/useNeighborhoods';
// import _default from 'react-bootstrap/esm/CardColumns';

const { beforeToday } = DateRangePicker;

export type TNeighborhoodListResponse = {
  total: number;
  rows: [{ count: number; name: string }];
};

export type TRecentParams = {
  location: string | null;
  minimumGuests: number;
  checkInDate: string;
  checkOutDate: string;
  customAmenities: string;
  typeProperties: string;
  minimumBedrooms: number;
  minimumBathrooms: number;
  kindOfProperties: string;
  page: number;
  limit: number;
  offset: number;
  favorites: boolean;
  orderBy?: string;
  uid?: string[];
  language?: string;
};

export interface Filter {
  location: string | null;
  minimumGuests: number;
  checkInDate: string;
  checkOutDate: string;
  customAmenities: string;
  typeProperties: string;
  minimumBedrooms: number;
  minimumBathrooms: number;
  kindOfProperties: string;
  orderBy?: string;
}

export enum FilterKeysNames {
  location = 'location',
  guests = 'guests',
  checkIn = 'checkIn',
  checkOut = 'checkOut',
  favorites = 'favorites',
  featured = 'featured',
  regular = 'regular',
  kindOfProperties = 'kindOfProperties',
}

interface FilterBoxParams {
  showFavorite: boolean;
  showFeatured: boolean;
  shadow: boolean;
  showClearFilter: boolean;
  sendFilters: (str: string, filter: Filter) => void;
  cleanFilter: boolean;
  forHome: boolean;
  inPropList: boolean;
  totalProperties: number;
}

interface FilterFavParams {
  setFilter: ({}: any) => void;
  showFavorite: any;
  filterObj: any;
}

const datePickerPlaceHolder = `${translate.t('labelDateFrom')} - ${translate.t(
  'labelDateTo',
)} `;

const getKindOfProperties = (customAmenities: string) => {
  let kindOfProperties = FilterKeysNames.regular;
  if (!customAmenities) {
    return kindOfProperties;
  }
  if (customAmenities.includes(FilterKeysNames.featured)) {
    kindOfProperties = FilterKeysNames.featured;
  }
  if (customAmenities.includes(FilterKeysNames.favorites)) {
    kindOfProperties = FilterKeysNames.favorites;
  }
  return kindOfProperties;
};

export const prepareStrParams = (filterObj: Filter) => {
  let strParams = '';
  if (filterObj?.location && filterObj.location !== 'null') {
    console.log(filterObj?.location);
    strParams += `&location=${filterObj.location}`;
  }
  if (filterObj?.minimumGuests) {
    strParams += `&guests=${filterObj.minimumGuests}`;
  }
  if (filterObj?.checkInDate) strParams += `&checkIn=${filterObj.checkInDate}`;
  if (filterObj?.checkOutDate) {
    strParams += `&checkOut=${filterObj.checkOutDate}`;
  }
  if (filterObj?.typeProperties) {
    strParams += '&typesProperties=' + filterObj.typeProperties;
  }
  if (filterObj?.minimumBedrooms) {
    strParams += '&minimumBedrooms=' + filterObj.minimumBedrooms;
  }
  if (filterObj?.minimumBathrooms) {
    strParams += '&minimumBathrooms=' + filterObj.minimumBathrooms;
  }
  if (filterObj?.orderBy) strParams += '&orderBy=' + filterObj.orderBy;

  let amenities: string = filterObj?.customAmenities
    ? filterObj?.customAmenities
    : '';
  amenities = amenities
    .replaceAll(FilterKeysNames.featured, '')
    .replaceAll(',' + FilterKeysNames.featured, '');

  if (
    filterObj.kindOfProperties === FilterKeysNames.featured &&
    !amenities.includes(FilterKeysNames.featured)
  ) {
    amenities += (amenities.length > 0 ? ',' : '') + 'featured';
  }
  if (amenities) {
    strParams += '&customAmenities=' + amenities;
  }
  strParams +=
    '&typeShow=' +
    (filterObj.kindOfProperties
      ? filterObj.kindOfProperties
      : FilterKeysNames.regular);
  return strParams;
};

const CustomDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={pin} alt="pin information" style={{ width: '14px' }} />
    </components.DropdownIndicator>
  );
};

const CustomDropdownIndicatorGuests = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={guests} alt="guests" style={{ width: '20px' }} />
    </components.DropdownIndicator>
  );
};

const CustomDropdownIndicatorOrder = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={blackArrow} alt="icons" style={{ width: '12px' }} />
    </components.DropdownIndicator>
  );
};

const capitalizeWords = (text: string) => {
  return text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const filterOptions = [
  { value: '', label: 'Default' },
  { value: 'lowest-price', label: 'Menor Precio' },
  { value: 'highest-price', label: 'Mayor Precio' },
];

const defaultOption = [
  {
    value: '',
    label: translate.t('allNeighborhoods').toString(),
  },
];

const defaultGuestOption = [
  {
    value: '',
    label: translate.t('totalGuests').toString(),
  },
];

const guestOptions = Variables.guestList.map((guest, iGuest) => {
  return {
    value: guest,
    label:
      guest === 1
        ? `${guest} ${translate.t('guest')}`
        : `${guest} ${translate.t('guests')}`,
  };
});

function FilterBox({
  totalProperties = 0,
  showFavorite = false,
  showFeatured = false,
  sendFilters,
  shadow = true,
  showClearFilter = false,
  cleanFilter = false,
  forHome = false,
  inPropList = false,
}: FilterBoxParams) {
  const location = useLocation();
  const [filterObj, setFilter] = useState(HELPER_TSX.getRecentParams(location));
  const [modalFilterVisibility, setModalFilterVisibility] = useState(false);
  const [isFirstLoad, setFirstLoad] = useState(true);
  const [selectedRange, setSelectedRange] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined]);
  const refRangePicker = useRef<PickerHandle | null>(null);
  const { loadingNeighborhoods, neighboorList: neighborhoods } =
    useNeighborhoods();

  function sendToList() {
    console.log('envia filtros');
    let strParams = prepareStrParams(filterObj);
    sendFilters(strParams, filterObj);
  }

  useEffect(() => {
    setFirstLoad(false);
    //if home and first load, return to prevent push to properties page by dependency array in useEffect
    if (isFirstLoad) return;

    console.log('after first load');
    sendToList();
  }, [
    filterObj.kindOfProperties,
    filterObj.minimumBathrooms,
    filterObj.minimumBedrooms,
    filterObj.orderBy,
    filterObj.customAmenities,
  ]);

  const setExtraFilters = (params: CustomFilter) => {
    setFilter({
      ...filterObj,
      customAmenities: params.amenities.join(','),
      minimumBathrooms: params.bathrooms,
      minimumBedrooms: params.bedrooms,
      typeProperties: params.propertyTypes.join(','),
    });
    setModalFilterVisibility(false);
  };

  const handleSelect = (newDate: Date) => {
    if (selectedRange[0] !== undefined && selectedRange[1] === undefined) {
      let dayDiff = moment(newDate).diff(moment(selectedRange[0]), 'days');
      if (dayDiff <= 0) {
        alert(translate.t('alertCheckoutWrongDate').toString());
        return;
      }

      setSelectedRange([selectedRange[0], newDate]);
      setFilter({
        ...filterObj,
        checkInDate: moment(selectedRange[0]).format(
          Variables.hostfullyDateFormat,
        ),
        checkOutDate: moment(newDate).format(Variables.hostfullyDateFormat),
      });

      console.log('aca');
      setTimeout(() => {
        const button = $('.rs-btn-primary');
        button.trigger('click');
      }, 500);
    } else {
      setSelectedRange([newDate, undefined]);
    }
  };

  const handleFilters = (newFilter: any) => {
    const oldAmenities = filterObj?.customAmenities?.split(',');
    const newAmenities = oldAmenities.filter((filter) => filter !== newFilter);
    const newAmenitiesString = newAmenities.join(',');
    setFilter((prevProps) => ({
      ...prevProps,
      customAmenities: newAmenitiesString,
    }));
  };

  const refreshPage = () => (window.location.href = window.location.pathname);

  const handleChangeProps = (e: any) => {
    console.log('val selected', e.value);
    setFilter({
      ...filterObj,
      location: e.value,
    });
  };

  const handleChangeGuests = (e: any) => {
    setFilter({
      ...filterObj,
      minimumGuests: parseInt(e.value),
    });
  };

  const handleChangeOrder = (evt: any) => {
    setFilter({ ...filterObj, orderBy: evt.value });
  };

  //datepicker setting up

  const pickerValue: DateRange | null = filterObj.checkInDate
    ? [
        moment(filterObj.checkInDate).toDate(),
        moment(filterObj.checkOutDate).toDate(),
      ]
    : null;

  let options: any = [];
  if (neighborhoods?.rows.length) {
    options = neighborhoods?.rows.map((neigh, iOptions) => {
      return {
        value: neigh.name,
        label: neigh.name,
      };
    });
  }

  const defaultNeighborhoodValue =
    filterObj.location && filterObj.location !== 'null'
      ? [{ value: filterObj.location, label: filterObj.location }]
      : [...defaultOption, ...options][0];

  return (
    <div
      id="filterContainerBlock"
      className={forHome ? 'filter-container-box' : 'filter-container-box-list'}
    >
      <div
        className={`search-home${cleanFilter ? '-clean' : null} ${
          !shadow ? 'no-shadow' : ''
        } sticky-filter`}
        style={{
          zIndex: '9999',
          background: 'white',
          color: 'black',
          transition: '1s',
        }}
      >
        <div
          className="map-filter-cont align-items-center no-padding-box"
          style={
            inPropList
              ? {
                  backgroundColor: '#E3E3E3',
                  padding: '8px 16px',
                }
              : forHome
              ? { top: '197px' }
              : {}
          }
        >
          <div className="filter-container-home pb-0">
            <div className="filter-mobile">
              <div className="col-md-3">
                <div className="home-filter-form form-group">
                  <label className="form-label">
                    {translate.t('location').toString()}
                  </label>
                  <Select
                    options={[...defaultOption, ...options]}
                    components={{ DropdownIndicator: CustomDropdownIndicator }}
                    onChange={handleChangeProps}
                    defaultValue={defaultNeighborhoodValue}
                    placeholder="Todos los barrios"
                    classNamePrefix="custom-select"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: 'transparent',
                        border: 'transparent',
                        borderBottom: '1px solid #606060',
                        color: '#a4a4a4',
                        fontFamily: 'SharpGrotesk',
                        fontSize: '14px',
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontSize: '14px',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: '14px',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#606060',
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className={`form-group${inPropList ? '-inProp' : ''}`}>
                  <label className="form-label">
                    {translate.t('dateOfStay').toString()}
                  </label>
                  <br></br>
                  {beforeToday !== undefined && (
                    <div className="custom-dp-container">
                      <DateRangePicker
                        ref={refRangePicker}
                        shouldDisableDate={beforeToday()}
                        onSelect={handleSelect}
                        showOneCalendar
                        defaultValue={pickerValue}
                        placeholder={datePickerPlaceHolder}
                        format="dd-MM-yyyy"
                        className="filter-my-date-range-picker-clean"
                        menuClassName="test"
                        appearance="subtle"
                        style={{ width: '100%' }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-3">
                <div className={`form-group`}>
                  <label className="form-label">
                    {translate.t('guests').toString()}
                  </label>
                  <Select
                    options={[...defaultGuestOption, ...guestOptions]}
                    components={{
                      DropdownIndicator: CustomDropdownIndicatorGuests,
                    }}
                    onChange={handleChangeGuests}
                    defaultValue={[...defaultGuestOption, ...guestOptions][0]}
                    classNamePrefix="custom-select"
                    placeholder={translate.t('totalGuests').toString()}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: 'transparent',
                        border: 'transparent',
                        borderBottom: '1px solid #606060',
                        paddingBottom: '5px',
                        fontFamily: 'SharpGrotesk',
                        fontSize: '14px',
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontSize: '14px',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: '14px',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#606060',
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="search-btn-filterbox">
                <div className="form-group">
                  <button
                    onClick={() => sendToList()}
                    className="btnW2ba-search"
                  >
                    <img src={lupa} alt="lupa" className="lupa-icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!cleanFilter ? (
          <div
            id="extraFiltersContainer"
            className="filters-btns-container pt-0 pb-0"
          >
            <div className="filters-btns">
              <div className="filter-orders-cont-mobile">
                <button
                  onClick={() =>
                    setModalFilterVisibility(!modalFilterVisibility)
                  }
                  className="filter-btn"
                >
                  {translate.t('btn-filter').toString()}
                  {modalFilterVisibility ? (
                    <img
                      style={{ marginLeft: '10px', width: '12px' }}
                      src={closeFilterIcon}
                      alt="close filter"
                      className="filter-icon-filters"
                    />
                  ) : (
                    <img
                      style={{ marginLeft: '10px', width: '20px' }}
                      src={filterIcon}
                      alt="filter search"
                      className="filter-icon-filters"
                    />
                  )}
                </button>
                <div className="order-by-props-mobile">
                  <Select
                    options={filterOptions}
                    components={{
                      DropdownIndicator: CustomDropdownIndicatorOrder,
                    }}
                    placeholder={String(translate.t('propertyList.orderBy'))}
                    onChange={(val) => handleChangeOrder(val)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: 'transparent',
                        border: 'transparent',
                        borderBottom: '2px solid #606060',
                        color: '#a4a4a4',
                        fontFamily: 'SharpGrotesk',
                        fontSize: '14px',
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontSize: '14px',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: '14px',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#A9A9A9',
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="filter-tag-container">
                {filterObj?.customAmenities?.length > 0 &&
                  filterObj?.customAmenities?.split(',').map(
                    (filter) =>
                      filter !== '' && (
                        <div className="filter-tag">
                          <span>
                            {translate
                              .t(
                                HELPER_TSX.fromAmenitieIndexToName(
                                  filter as AmenityKey,
                                ),
                              )
                              .toString()}
                          </span>
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleFilters(filter)}
                          >
                            x
                          </span>
                        </div>
                      ),
                  )}
              </div>
            </div>

            <div className="modal-filter-cont">
              <ModalFilter
                isVisible={modalFilterVisibility}
                toggleVisible={setModalFilterVisibility}
                filters={filterObj}
                onOk={setExtraFilters}
                refreshPage={refreshPage}
              />
            </div>

            <div className="info-results-container">
              <div className="d-flex">
                <div className="total-props-cont2" id="elementToScroll">
                  <span className="prop-quantity-text">{totalProperties}</span>{' '}
                  <span className="prop-text">
                    {String(translate.t('propertyList.searchResultTitle'))}
                  </span>
                </div>
                <div className="order-by-props">
                  <Select
                    options={filterOptions}
                    components={{
                      DropdownIndicator: CustomDropdownIndicatorOrder,
                    }}
                    onChange={(val) => handleChangeOrder(val)}
                    placeholder={String(translate.t('propertyList.orderBy'))}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: 'transparent',
                        border: 'transparent',
                        color: '#a4a4a4',
                        fontFamily: 'SharpGrotesk',
                        height: '40px',
                        fontSize: '10px',
                        marginLeft: '20px',
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontSize: '10px',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: '10px',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#A9A9A9',
                      }),
                    }}
                  />
                  <hr />
                </div>
              </div>

              <div className="d-flex">
                <FeaturedButton
                  setFilter={setFilter}
                  filterObj={filterObj}
                  showFavorite={showFavorite}
                />
                <FavButton
                  setFilter={setFilter}
                  filterObj={filterObj}
                  showFavorite={showFavorite}
                />
              </div>
            </div>

            <div className="clear"></div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default FilterBox;

const FeaturedButton = ({
  setFilter,
  showFavorite,
  filterObj,
}: FilterFavParams) => {
  const favSelected = filterObj.kindOfProperties === FilterKeysNames.featured;
  const kindOfProperties = favSelected
    ? FilterKeysNames.regular
    : FilterKeysNames.featured;
  const btnClass = favSelected
    ? { border: '1px solid #A0A0A0', borderRadius: '0px 50px 50px 0px' }
    : {};

  return (
    <div className="fav-container">
      {showFavorite && (
        <button
          type="button"
          style={btnClass}
          className={`float-r filter-btn btn btn-featured   fs-xs uppercase ${
            favSelected ? 'btn-featured-full' : ''
          }`}
          onClick={(e) => {
            setFilter({
              ...filterObj,
              kindOfProperties: kindOfProperties,
            });
          }}
        >
          <img
            src={favSelected ? startOn : startOff}
            alt="featured"
            className=" "
          />
          <span style={{ fontSize: '10px' }}>
            {translate.t('featured').toString()}
          </span>
        </button>
      )}
    </div>
  );
};

const FavButton = ({ setFilter, showFavorite, filterObj }: FilterFavParams) => {
  const favSelected = filterObj.kindOfProperties === FilterKeysNames.favorites;
  const kindOfProperties = favSelected
    ? FilterKeysNames.regular
    : FilterKeysNames.favorites;
  const btnClass = favSelected
    ? { border: '1px solid #A0A0A0', borderRadius: '0px 50px 50px 0px' }
    : {};

  return (
    <div className="fav-container">
      {showFavorite && (
        <button
          type="button"
          style={btnClass}
          className={`float-r filter-btn btn btn-featured   fs-xs uppercase ${
            favSelected ? 'btn-featured-full' : ''
          }`}
          onClick={(e) => {
            setFilter({
              ...filterObj,
              kindOfProperties: kindOfProperties,
            });
          }}
        >
          <img
            src={favSelected ? redHeart : blackHeart}
            alt="favorites"
            className=" "
          />
          <span style={{ fontSize: '10px' }}>
            {translate.t('favorites').toString()}
          </span>
        </button>
      )}
    </div>
  );
};
