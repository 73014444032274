import { useEffect, useState } from 'react';
import { PropertyService } from '../services/property.service';
import { IProperty } from '../interfaces/hostfully.property.interface';
import { getElementsOfLocalStorage } from '../FavoriteProperties';
import { TRecentParams } from '../filter/FilterContainer';

export default function useProperties(filters: TRecentParams) {
  const [propertiesList, setProperties] = useState<IProperty[]>([]);
  const [totalProperties, setTotalProperties] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (filters.favorites) {
      const propsInLocalStorage = getElementsOfLocalStorage('props');
      const propsIds = propsInLocalStorage.map((propId: string) => propId);
      filters.uid = propsIds;
    }
    const lang = localStorage.getItem('country') ?? 'es';
    filters.language = lang;

    PropertyService.getProperties(filters).then((properties) => {
      setProperties(properties.rows);
      setTotalProperties(properties.total);
      setLoading(false);
    });
  }, [
    filters.checkInDate,
    filters.checkOutDate,
    filters.favorites,
    filters.location,
    filters.minimumGuests,
    filters.offset,
    filters.customAmenities,
    filters.kindOfProperties,
    filters.minimumBathrooms,
    filters.minimumBedrooms,
    filters.orderBy,
  ]);

  return { loading, propertiesList, totalProperties };
}
