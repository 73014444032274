import Helper from '../utils/Helper';
import '../components/componentStyles/FooterStyle.scss';
import insta from '../assets/icons/ig-new.svg';
import face from '../assets/icons/facebook-new.svg';
import cards from '../assets/icons/cards.png';
import translate from '../components/languages';
import { useHistory } from 'react-router-dom';
import ModalContact from './ContactModal';
import greenLogo from '../assets/1. INICIO/greenlogo.png';
import DivisorLine from '../utils/divisor-line/DivisorLine';
import { useState } from 'react';
import moment from 'moment';

function Footer() {
  let history = useHistory();
  const [modalShow, setModalShow] = useState(false);

  const setOffModalParent = () => {
    setModalShow(false);
  };

  const currentYear = moment().toDate().getFullYear();
  return (
    <div className="footer-container">
      <div className="logo-rights">
        <div className="logo-container">
          <img src={greenLogo} width="100%" alt="Loading..." />
        </div>
        <div className="rights-desktop mt-4 font-2">
          <strong>Copyright © {new Date().getFullYear()} Welcome2BA </strong>
          <span> {translate.t('allRightsReserved')}</span>
        </div>
      </div>
      <div className="show-only-desktop">
        <DivisorLine />
      </div>
      <div className="columns-container">
        <div className="about-us-column">
          {/* <div className="nav-element col font-2 cursor-footer"><span>{translate.t('aboutUs2')}</span></div> */}
          <div className="nav-element col font-2 cursor-footer">
            <span onClick={() => history.push('/history')}>
              {translate.t('aboutUs')}
            </span>
          </div>
          <div className="nav-element col font-2 cursor-footer">
            <span onClick={() => history.push('/faqs')}>FAQ's</span>
          </div>
          <div className="nav-element col font-2 cursor-footer">
            <span onClick={() => history.push('/termsandconditions')}>
              {translate.t('terms')}
            </span>
          </div>
          <div className="contact-modal-mobile">
            <div className="col font-2 cursor-footer">
              <span onClick={() => setModalShow(true)}>
                {translate.t('contact')}
              </span>
            </div>
            <ModalContact
              show={modalShow}
              setOffModalParent={setOffModalParent}
            />
          </div>
        </div>

        <div className="show-only-mobile mb-3 mt-3">
          <DivisorLine />
        </div>

        <div className="contact-column">
          <div className="nav-element font-2">
            {translate.t('contactMayus')}
          </div>
          <div className="nav-element font-2">WhatsApp +54 1125229682</div>
        </div>

        <div className="social-column">
          <img
            src={face}
            style={{ width: 'auto', height: '2.2em' }}
            onClick={() => window.open('https://www.facebook.com/Welcome2ba')}
            className="logo-sociales mr-4 no-padding-left"
            alt="Loading..."
          />
          <img
            src={insta}
            style={{ width: 'auto', height: '2.3em' }}
            onClick={() => window.open('https://www.instagram.com/welcome2ba')}
            className="logo-sociales mr-3"
            alt="Loading..."
          />
          <div
            className="lang-sect-footer black"
            style={{ marginLeft: '13px', height: 'fit-content' }}
          >
            <select
              className="navBarLanguage input-navbar-lang black"
              value={Helper.getLanguage()}
              onChange={(evt) => Helper.changeLanguage(evt.target.value)}
            >
              <option value={Helper.LANGUAGE_VARS.SPANISH}>ES</option>
              <option value={Helper.LANGUAGE_VARS.ENGLISH}>EN</option>
            </select>
            <span className="material-symbols-outlined">expand_more</span>
          </div>
        </div>

        <div className="show-only-mobile mb-3">
          <DivisorLine />
        </div>

        <div className="rights-mobile font-2">
          <strong>Copyright © {currentYear} Welcome2BA</strong>
          <br />
          {translate.t('allRightsReserved')}
        </div>

        <div className="credit-cards">
          <img src={cards} width="100%" alt="Loading..." />
        </div>
      </div>
    </div>
  );
}
export default Footer;
