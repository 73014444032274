import React, { useState } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import '../components/componentStyles/ModalStyle.css';
import translate from '../components/languages';
import API from '../utils/API';


function MyVerticallyCenteredModal(props) {

  const [modalShow, setModalShow] = useState(false);
  const [name, setName] = useState('');
  const [celPhone, setCelPhone] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [nation, setNation] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zone, setZone] = useState('');
  const [typeProp, setTypeProp] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  function sendData() {
      var data = {
          email: email,
          emailConfirm: emailConfirm,
          status: 'NEW',
          firstName: name,
          cel: celPhone,
          phone: phone,
          address: address,
          zone: zone,
          TypeProperty: typeProp
      };
      API.Web.postLeadV1(data);


      let str = `Nuevo contacto por publicar una propiedad \n`;
      str += `nombre:${name} \n`;
      str += `celular:${celPhone} \n`;
      str += `telefono:${phone} \n`;
      str += `email:${email} \n`;
      str += `nacionalidad:${nation} \n`;
      str += `direccion:${address} \n`;
      str += `ciudad:${city} \n`;
      str += `zona:${zone} \n`;
      str += `tipo de propiedad:${typeProp} \n`;
      str += `mensaje:${message} \n`;
      API.Web.postSlackMessage({ "text": str })
      setShowSuccessMessage(true);


      

  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="title-modal">
          Publique su Propiedad
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h5 className="text-modal">Información del Propietario</h5>
          <Form>
            <Row>
              <Col>
                <input 
                  className="forms-modal" 
                  placeholder="Nombre Completo"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  />
              </Col>
            </Row>
            <Row>
              <Col>
                <input 
                  className="forms-modal" 
                  placeholder="Teléfono Celular"
                  value={celPhone}
                  onChange={(e) => setCelPhone(e.target.value)}
                />
              </Col>
              <Col>
                <input 
                  className="forms-modal" 
                  placeholder="Teléfono Fijo"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  />
              </Col>
            </Row>
            <Row>
              <Col>
                <input 
                  className="forms-modal" 
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />
              </Col>
            </Row>
            <Row>
              <Col>
                <input 
                  className="forms-modal" 
                  placeholder="Confirmación de E-mail" 
                  value={emailConfirm}
                  onChange={(e) => setEmailConfirm(e.target.value)}
                  />
              </Col>
            </Row>
            <Row>
              <Col>
                <input 
                  className="forms-modal" 
                  placeholder="Nacionalidad" 
                  value={nation}
                  onChange={(e) => setNation(e.target.value)}
                  />
              </Col>
            </Row>
          </Form>
        </div>
      </Modal.Body>
      <br />
      <Modal.Body>
        <h5 className="text-modal">Información de la propiedad</h5>
        <Form>
          <Row>
            <Col>
              <input 
                className="forms-modal" 
                placeholder="Dirección" 
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                />
            </Col>
          </Row>
          <Row>
            <Col>
              <input 
                className="forms-modal" 
                placeholder="Ciudad" 
                value={city}
                onChange={(e) => setCity(e.target.value)}
                />
            </Col>
          </Row>
          <Row>
            <Col>
              <input 
                className="forms-modal" 
                placeholder="Zona" 
                value={zone}
                onChange={(e) => setZone(e.target.value)}
              />
            </Col>
            <Col>
              <input 
                className="forms-modal" 
                placeholder="Tipo de propiedad" 
                value={typeProp}
                onChange={(e) => setTypeProp(e.target.value)}
                />
            </Col>
          </Row>
          <Row>
            <Col>
              <textarea 
                className="forms-modal" 
                placeholder="Descripción" 
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                />
            </Col>
          </Row>
          {/*<div>
            <Form>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="¿Desea recibir nuestro newsletter?"
              />
            </Form>
          </div>*/}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {showSuccessMessage
        ?
        <button onClick={() => setModalShow(false)} className="button-modal">{translate.t('modalContactThanks')}</button>
        :
        <button onClick={() => sendData()} className="button-modal">{translate.t('modalContactSend')}</button>}
      </Modal.Footer>
    </Modal>
  );
}

function ModalPublish() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <button className="button-publish" onClick={() => setModalShow(true)}>{translate.t('startNow')}</button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default ModalPublish;