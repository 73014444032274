import React, { useState, Suspense } from 'react';
import NavBar from '../components/NavBar';

import '../components/componentStyles/HomeStyle.scss';
import '../components/componentStyles/CarouselHomeMobile.css';
import '../components/componentStyles/CarouselHomeTravelers.css';

//settings
import translate from '../components/languages';
import { useHistory } from 'react-router-dom';

import FilterBox from '../utils/filter/FilterContainer';

import 'rsuite/dist/rsuite.min.css';
import '../utils/filter/SpecialFilters.scss';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import MainStaticBanner from './Mainbanner/MainStaticBanner';
import FilterBoxMobile from '../utils/filter/FilterBoxMobile/FilterBoxMobile';
import '../utils/filter/FilterBoxMobile/FilterBoxMobile.scss';
import { isMobile } from 'react-device-detect';

//Lazy load
const Collage = React.lazy(() => import('./Collage/Collage'));
const JoinWelcome = React.lazy(() => import('./JoinWelcome/JoinWelcome'));
const BrandBanner = React.lazy(() => import('./BrandBanner/BrandBanner'));
const Declarations = React.lazy(() => import('./Declarations/Declarations'));
const Footer = React.lazy(() => import('../components/Footer'));
// import Collage from './Collage/Collage';
// import JoinWelcome from './JoinWelcome/JoinWelcome';
// import BrandBanner from './BrandBanner/BrandBanner';
// import Declarations from './Declarations/Declarations';
// import Footer from '../components/Footer';

const OurSelection = React.lazy(() => import('./Home/OutSelection'));
const AmenetiesText = React.lazy(() => import('./Home/AmenetiesText'));
const PhrasesCarousel = React.lazy(() => import('./Home/PhrasesCarousel'));
// import OurSelection from './Home/OutSelection';
// import AmenetiesText from './Home/AmenetiesText';
// import PhrasesCarousel from './Home/PhrasesCarousel';

AOS.init();

function Home() {
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const sendToSearch = (strParams, params) => {
    console.log('send to search');
    history.push('/properties?' + strParams, params);
  };

  console.log('aca', isOpen);

  return (
    <div className="w2ba-main-section">
      <NavBar />
      <FindSection />
      {!isMobile && (
        <div className="filter-box-desktop">
          <FilterBox
            sendFilters={sendToSearch}
            showClearFilter={false}
            cleanFilter={true}
            forHome={true}
            forVideoBanner={false}
          />
        </div>
      )}
      {isMobile && (
        <div className="filter-box-mobile">
          <FilterBoxMobile
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            sendToSearch={sendToSearch}
          />
        </div>
      )}
      <W2BAphrase />
      {isOpen && <div style={{ height: '100px' }}></div>}

      <Suspense fallback={<div>Loading...</div>}>
        <OurSelection />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <AmenetiesText />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <PhrasesCarousel />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <Collage />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <JoinWelcome />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <BrandBanner />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <Declarations />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>
    </div>
  );
}
export default Home;

function FindSection(props) {
  return (
    <div>
      <MainStaticBanner props={props} />
    </div>
  );
}

function W2BAphrase() {
  return (
    <div className="home-cont-background">
      <div className="home-cont">
        <span className="phrase-dots">●●</span>
        <p>{translate.t('secondBannerPhrase')}</p>
        <p style={{ fontWeight: '400' }}>Welcome2BA team</p>
      </div>
    </div>
  );
}
