import React, { useState } from 'react';
import './Toggle.scss';

const Toggle = ({ title, body }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion toggle-container">
      <div className="accordion-item ">
        <div
          className="accordion-header d-flex justify-content-between "
          id="headingOne"
          onClick={toggleAccordion}
        >
          <button
            className={`accordion-button text-left ${
              !isOpen ? 'collapsed' : ''
            }`}
            type="button"
          >
            <span className="toggle-container-span">{title}</span>
          </button>
          <span
            className="material-symbols-outlined arrow"
            style={{ fontSize: '32px' }}
          >
            {isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_right'}
          </span>
        </div>
        <div
          id="collapseOne"
          className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
        >
          <div className="accordion-body">
            <span className="toggle-container-span">{body}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Toggle;
