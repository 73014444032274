import { useState } from 'react';
import './CustomPropertyCard.scss';
import useQuote from '../../hooks/useQuote';

export const prepareTitle = (title) => {
  let titleSplitted;

  if (title !== undefined && title.includes('|'))
    titleSplitted = title.split(' | ');
  else titleSplitted = title.split(':');

  const mainTitle = titleSplitted.shift(); // Get the first element

  return mainTitle;
};

export const prepareSubTitle = (title) => {
  let titleSplitted;

  if (title !== undefined && title.includes('|'))
    titleSplitted = title.split(' | ');
  else titleSplitted = title.split(':');

  const sub =
    titleSplitted.filter((part) => part.trim()).join(' | ') ||
    'Welcome2BA Team';

  return sub;
};

const CustomPropertyCard = ({ uid, image, title, price, url }) => {
  const [titleMod] = useState(prepareTitle(title));
  const [subTitleMod] = useState(prepareSubTitle(title));

  return (
    <div className="card" onClick={() => (window.location.href = url)}>
      <div className="img-cont">
        <img src={image} alt={title} className="prop-img" />
        <PriceContainer uid={uid} />
      </div>
      <span className="property-name">{titleMod}</span>
      <span className="property-subTitle">{subTitleMod}</span>
    </div>
  );
};

const PriceContainer = ({ uid }) => {
  const { quote } = useQuote({ uid });

  return (
    <div className="price-container">
      <span className="price">Desde</span>
      <div className="price-usd-container">
        <span className="price-usd pr-1">u$d</span>
        <span className="price-usd-value">
          {quote?.dailyPrice ? quote.dailyPrice : '...'}
        </span>
      </div>
    </div>
  );
};

export default CustomPropertyCard;
