import i18n from "i18next";

// Catalogo
import spanish from "./countrys/spanish.json";
import english from "./countrys/english.json"

// Detectar Idioma
const country = localStorage.getItem('country');
var lng = country === 'en' ? 'en' : 'es';


// Translation catalog
const resources = { es: { translation: spanish }, en: { translation: english } };

// Initialize i18next with catalog and language to use
i18n.init({ resources, lng: lng });

export default i18n;