import React,{useState,useEffect} from 'react';
import '../components/componentStyles/PublishStyle.css';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import ModalPublish from '../components/Modal';
import Trip from '../assets/tripadvisor.svg';
import HomeAway from '../assets/homeaway.svg';
import Booking from '../assets/booking.svg';
import Air from '../assets/airbnb.svg';
import Alqui from '../assets/4. PUBLICAR/21.jpg';
import Alqui2 from '../assets/4. PUBLICAR/22.jpg';
import Alqui3 from '../assets/4. PUBLICAR/23.jpg';
import Alqui4 from '../assets/4. PUBLICAR/24.jpg';
import Alqui5 from '../assets/4. PUBLICAR/25.jpg';
import footer from '../assets/1. INICIO/23.svg';
import Asistente from '../assets/4. PUBLICAR/48.svg';
import Aspiradora from '../assets/4. PUBLICAR/49.svg';
import Calendario from '../assets/4. PUBLICAR/47.svg';
import Lavarropas from '../assets/4. PUBLICAR/50.svg';
import Toallas from '../assets/4. PUBLICAR/51.svg';
import AmenitiesBanos from '../assets/4. PUBLICAR/52.svg';
import Regalo from '../assets/4. PUBLICAR/54.svg';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import translate from '../components/languages';
import API from '../utils/API';



const widthIcons = 55;

function ScreenPublish() {

    /*const [firstLoad,setFirstLoad] = useState(true);
    useEffect(() => {
        if(firstLoad){
            console.log('quiere batch');
            setFirstLoad(false);
            //API.Web.removeBatchCustomAmenities();
            API.Web.addBatchCustomAmenities();
        }
    },[])*/

    return (
        <div>
            <NavBar />

            <RentUs />
            <WeCare />

            <Footer />
        </div>
    )
}

export default ScreenPublish;

function RentUs() {
    return (
        <div>
            <div className="background-publish-title">
                <div className="container">
                    <div className="title-publish"><p>{translate.t('rentWithUs')}</p></div>
                    <div className="subtitle-publish"><p>{translate.t('numberReservs')}</p></div>
                </div>
            </div>

            <div className="container">
                <div className="row mt-5">
                    <div className="col-lg-6 col-sm-12">
                        <div className="titulo-gestion pt-5"><p>{translate.t('completeManagement')}<br />{translate.t('forProperty')}</p></div>
                        <div>
                            <div className="text-xs-left">
                                <div className="texto-publish mt-5 mb-4">
                                    <p>{translate.t('weProvide')}
                                    <br />
                                    <br />
                                    {translate.t('weTakeCare')}</p>
                                </div>
                                <div className="row img-publish">
                                    <div className="col-3"><img src={Air} width="100%" alt="Loading..."/></div>
                                    <div className="col-3"><img src={Booking} width="100%" height="100%" alt="Loading..."/></div>
                                    <div className="col-3"><img src={HomeAway} width="100%" height="100%" alt="Loading..."/></div>
                                    <div className="col-3"><img src={Trip} width="100%" height="100%" alt="Loading..."/></div>
                                </div>
                                <div className="mt-5 mb-5">
                                    <ModalPublish />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 no-padding-lateral">
                        <img src={Alqui} width="100%" alt="Loading..."/>
                    </div>
                </div>
            </div>

            <div className="container">
                <ContainerComission />
                <div className="row align-center mt-3">
                    <div className="col-12">
                        <p className="texto-publish">{translate.t('rateIncludes')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ContainerComission() {
    return (
        <div>
            <BrowserView>
                <div className="align-center">
                    <div className="row box-tarifa">
                        <div style={{ padding:20 }} className="col-md-4 px-4 back-tarifa text-tarifa-white b-radius-left-top-50 b-radius-left-bottom-50 b-right-none">
                            <span>{translate.t('simpleRate')}</span>
                        </div>
                        <div className="col-md-4 px-4">
                            <span className="text-tarifa-green">{translate.t('comission')}</span>
                            <p className="texto-publish">{translate.t('grossAmount')}</p>
                        </div>
                        <div className="col-md-4 px-4 b-radius-right-top-50 b-radius-right-bottom-50 b-left-none">
                            <span className="text-tarifa-green">{translate.t('netValue')}</span>
                            <p className="texto-publish">{translate.t('termsOfPayment')}</p>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <div className="align-center">
                    <div className="box-tarifa">
                        <div className="col-sm back-tarifa text-tarifa-white item b-radius-left-top-15 b-radius-right-top-15">
                            <span >{translate.t('simpleRate')}</span>
                        </div>
                        <div className="col-sm item">
                            <span className="text-tarifa-green">{translate.t('comission')}</span>
                            <p className="texto-publish">{translate.t('grossAmount')}</p>
                        </div>
                        <div className="col-sm item b-radius-right-bottom-15 b-radius-left-bottom-15">
                            <span className="text-tarifa-green">{translate.t('netValue')}</span>
                            <p className="texto-publish">{translate.t('termsOfPayment')}</p>
                        </div>
                    </div>
                </div>
            </MobileView>
        </div>
    )
}

function WeCare() {

    return (
        <div>
            <div>
                <div className="title-publish-2 mt-5">
                    <p>{translate.t('weTakeCarePublish')}</p>
                </div>
                <div className="subtitle-publish-2">
                    <p>{translate.t('justEnjoy')}</p>
                </div>
            </div>
            {/**LEFT PIC */}
            <div className="container">
                <div className="row mt-5">
                    <div className="col-lg-7 col-sm-12 no-padding-lateral">
                        <img src={Alqui2} width="100%" alt="Loading..."/>
                    </div>

                    <div className="col-lg-5 col-sm-12">
                        <div className="pt-3 padding-left-publish">
                            <div className="row">
                                <div className="col-lg-12 col-sm-6">
                                    <div className="align-left-browser align-center-mobile">
                                        <img src={Calendario} width={widthIcons} alt="Loading..."/>
                                    </div>
                                    <div>
                                        <div className="title-servicio-publish mt-2 align-center-mobile">
                                            <p>Check-in<br />Check-out</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="texto-servicio-publish align-center-mobile"><p>{translate.t('checkinText')}</p></div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-6">
                                    <div className="align-left-browser align-center-mobile">
                                        <img src={Asistente} width={widthIcons} alt="Loading..."/>
                                    </div>
                                    <div>
                                        <div className="title-servicio-publish mt-2 align-center-mobile">
                                            <p>{translate.t('guestIcon')}<br />{translate.t('managementIcon')}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="texto-servicio-publish align-center-mobile"><p>{translate.t('guestManagementText')}</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/**RIGHT PIC */}
            <div className="container">
                <div className="row mt-5">
                    <div className={`col-lg-5 col-sm-12 ${isMobile && 'order-2'}`}>
                        <div className="margin-left-publish">
                            <div className="row">
                                <div className="col-lg-12 col-sm-6">
                                    <div className="align-left-browser align-center-mobile">
                                        <img src={Aspiradora} width={widthIcons} alt="Loading..."/>
                                    </div>
                                    <div>
                                        <div className="title-servicio-publish mt-2 align-center-mobile">
                                            <p>{translate.t('professionalIcon')}<br />{translate.t('cleanIcon')}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="texto-servicio-publish align-center-mobile"><p>{translate.t('professionalCleanText')}</p></div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-6">
                                    <div className="align-left-browser align-center-mobile">
                                        <img src={Lavarropas} width={widthIcons} alt="Loading..."/>
                                    </div>
                                    <div>
                                        <div className="title-servicio-publish mt-2 align-center-mobile">
                                            <p>{translate.t('laundryIcon')}<br />{translate.t('serviceIcon')}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="texto-servicio-publish align-center-mobile"><p>{translate.t('laundryServiceText')}</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 col-sm-12 mb-3 no-padding-lateral">
                        <img src={Alqui3} width="100%" alt="Loading..."/>
                    </div>
                </div>
            </div>

            {/**LEFT PIC */}
            <div className="container">
                <div className="row mt-5">
                    <div className="col-lg-7 col-sm-12 mb-3 no-padding-lateral">
                        <img src={Alqui4} width="100%" alt="Loading..."/>
                    </div>
                    <div className="col-lg-5 col-sm-12">
                        <div className="padding-left-publish">
                            <div>
                                <div className="align-left-browser align-center-mobile">
                                    <img src={Toallas} width={widthIcons} alt="Loading..."/>
                                </div>
                                <div>
                                    <div className="title-servicio-publish mt-2 align-center-mobile">
                                        <p>{translate.t('sheetsIcon')}<br />{translate.t('towelsIcon')}</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="texto-servicio-publish align-center-mobile">
                                        <p>{translate.t('towels5*Text')}</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="texto-servicio-publish align-center-mobile">
                                        <p><strong>{translate.t('whiteSet')}</strong></p>
                                        <p>{translate.t('whiteSetText')}</p>
                                        <div className="linea-publish"></div>
                                        <div className="title-elementos-publish mt-3"><p>{translate.t('detailElements')}</p></div>
                                        <div className="row list-publish">
                                            <div className="col no-padding-lateral">
                                                <ul>
                                                    <li>{translate.t('smallTowels')}</li>
                                                    <li>{translate.t('largeTowels')}</li>
                                                    <li>{translate.t('bathFoot')}</li>
                                                    <li>{translate.t('sheets')}</li>
                                                </ul>
                                            </div>
                                            <div className="col no-padding-lateral">
                                                <ul>
                                                    <li>{translate.t('pillows')}</li>
                                                    <li>{translate.t('pillowCases')}</li>
                                                    <li>{translate.t('downDuvet')}</li>
                                                    <li>{translate.t('duvetCover')}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="publish-back">  {/**<------- div del background verde claro */}
                {/**RIGHT PIC */}
                <div className="container">
                    <div className="row mt-5">
                        <div className={`col-lg-5 col-sm-12 ${isMobile && 'order-2'}`}>
                            <div className="margin-left-publish">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-6">
                                        <div className="align-left-browser align-center-mobile">
                                            <img src={AmenitiesBanos} width={widthIcons} alt="Loading..."/>
                                        </div>
                                        <div>
                                            <div className="title-servicio-publish mt-2 align-center-mobile">
                                                <p>{translate.t('amenitieSetIcon')}<br />{translate.t('amenitieSetBathIcon')}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="texto-servicio-publish align-center-mobile">
                                                <p>{translate.t('amenitieSetText')}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="texto-servicio-publish pr-5">
                                                <div className="linea-publish"></div>
                                                <div className="title-elementos-publish mt-3"><p>{translate.t('detailElements')}</p></div>
                                                <div className="row list-publish">
                                                    <div className="col no-padding-lateral">
                                                        <ul>
                                                            <li>{translate.t('toiletSoap')}</li>
                                                            <li>Shampoo</li>
                                                            <li>{translate.t('toiletPaper')}</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col no-padding-lateral">
                                                        <ul>
                                                            <li>{translate.t('bathFoot')}</li>
                                                            <li>{translate.t('conditioner')}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-6">
                                        <div className="align-left-browser align-center-mobile">
                                            <img src={Regalo} width={widthIcons} alt="Loading..."/>
                                        </div>
                                        <div>
                                            <div className="title-servicio-publish mt-2 align-center-mobile">
                                                <p>{translate.t('careDetailsIcon')}<br />{translate.t('careDetailsIcon2')}</p>
                                            </div>
                                        </div>
                                        {/*<div>
                                            <div className="texto-servicio-publish align-center-mobile">
                                                <p>Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Duis sit amet sollicitudin nisl,
                                            quis imperdiet purus.</p>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-sm-12 mb-3 no-padding-lateral">
                            <img src={Alqui5} width="100%" alt="Loading..."/>
                        </div>
                    </div>
                </div>

                <div className="background-publish-button mt-5">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-md-auto">&nbsp;</div>
                            <div className="col-md-auto align-center-mobile"><ModalPublish /></div>
                            <div className="col-md-auto">&nbsp;</div>
                        </div>
                    </div>
                </div>

                <div className="d-none d-sm-block mt-5">
                    <img src={footer} width="100%" alt="Loading..."/>
                </div>

            </div> {/**<------- div del background verde claro */}
        </div>
    )
}