import { Skeleton } from "../PropertySkeleton"
import './SkeletonsContainer.scss'

export const SkeletonsContainer = () => {
    return <div className="skeleton-container mt-3" style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}} >
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
    </div>
}