import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useLocation,
} from 'react-router-dom';
import notFound from './assets/fogg-page-not-found-1.png';
import ScreenProperties from './Views/Properties';
import Home from './Views/Home';
import Checkout from './Views/Checkout/Checkout';
import ScreenHistory from './Views/AboutUs/History';
import ScreenDesign from './Views/Design';
import ScreenPublish from './Views/Publish';
import FaqScreen from './Views/Faq';
import TermsScreen from './Views/Terms';
import ScrollToTop from './components/ScrollToTop';

import Partners from './Views/Partners/Partners';

import WorkTrips from './Views/WorkTrips/WorkTrips';

function App() {
  // const [isFirstLoading, setFirstLoading] = useState(true);

  // useEffect(() => {
  //   if (isFirstLoading) {
  //     console.log('llegmos a app js');

  //     //Controlling amenities loaded
  //     // API.Web.getAllCustomAmenitiesByPropCount();

  //     //Adding amenities
  //     // API.Web.addBatchCustomAmenities();

  //     //Deletiong form
  //     // API.Web.removeBatchCustomAmenities();
  //     // API.Web.addBatchCustomAmenities();
  //   }
  //   setFirstLoading(false);
  // }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <ScrollToTop />
          <Home />
        </Route>
        <Route path="/properties">
          <ScrollToTop />
          <ScreenProperties />
        </Route>
        <Route path="/partners">
          <ScrollToTop />
          <Partners />
        </Route>
        <Route path="/design">
          <ScrollToTop />
          <ScreenDesign />
        </Route>
        <Route path="/publish">
          <ScrollToTop />
          <ScreenPublish />
        </Route>
        <Route path="/history">
          <ScrollToTop />
          <ScreenHistory />
        </Route>
        <Route path="/checkout">
          <ScrollToTop />
          <Checkout />
        </Route>
        <Route path="/business-trip">
          <ScrollToTop />
          <WorkTrips />
        </Route>
        <Route path="/faqs">
          <ScrollToTop />
          <FaqScreen />
        </Route>
        <Route path="/termsandconditions">
          <ScrollToTop />
          <TermsScreen />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Router>
  );
}

const NoMatch = () => {
  let location = useLocation();

  return (
    <section className={'PaginaNoEncontrada'}>
      <h1>¡Ups!</h1>
      <br />
      <p>
        ¿Estas perdido? <Link to="/">Vuelve al Inicio</Link>
      </p>
      <br />
      <img alt="404 - Pagina no encontrada D:" src={notFound} />
      <code>{location.pathname}</code>
    </section>
  );
};

export default App;
