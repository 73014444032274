import { useState } from 'react';
import '../components/componentStyles/DesignStyle.css';
import '../components/componentStyles/CarouselDesingStyle.css';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import Interior from '../assets/3. INTERIORES/19.jpg';
import imgReprod from '../assets/8. NUMEROS/Reprod1.jpg';
import number1 from '../assets/8. NUMEROS/20.svg';
import number2 from '../assets/8. NUMEROS/21.svg';
import number3 from '../assets/8. NUMEROS/22.svg';
import number4 from '../assets/8. NUMEROS/23.svg';
import { BrowserView, MobileView } from 'react-device-detect';
import translate from '../components/languages';
import ModalSchedule from '../components/ModalSchedule';


function ScreenDesign() {
    return (
        <div>
            <NavBar />
            <DesignTitle />
            <Objetives />
            <Process />
            <Footer />
        </div>
    )
}



const heightNumbers = 95;

function DesignTitle() {
    const [showIframe, setShowIframe] = useState(false);
    return (
        <div>
            <div className="background-design-title">
                <div className="container">
                    <span className="title-design">{translate.t('incredibleResult')}<br />{translate.t('spacesWithCharacter')}</span>
                </div>
            </div>
            <div className="container-lg mt-4 no-padding-lateral">
                <BrowserView>
                    <div className="row">
                        <div className="col-md-8 col-sm-12 video-section-design no-padding-lateral">

                            {!showIframe
                                ?
                                <img src={imgReprod} width="100%" height="100%" onClick={() => setShowIframe(true)} style={{ cursor: 'pointer' }} alt="Loading..." />
                                :
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src="https://www.youtube.com/embed/BnF3Y4S8OAg"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen={true}
                                    rel="0"
                                    showinfo="0"
                                    controls="1">
                                </iframe>}

                        </div>
                        <div className="col-md-4 col-sm-12 center-back" >
                            <div className="potencial-back">
                                <div className="pl-4 pt-4 pb-4 pr-2">
                                    <div className="col-12">
                                        <div className="title-design-2 mb-3"><p>{translate.t('maxPotencialDesign')}</p></div>
                                        <div className="texto-design-2">
                                            <p>{translate.t('maxPotencialText')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BrowserView>

                <MobileView>
                    <div className="video-section-design-mobile no-padding-lateral">
                        {!showIframe
                            ?
                            <img src={imgReprod} width="100%" height="100%" onClick={() => setShowIframe(true)} style={{ cursor: 'pointer' }} alt="Loading..." />
                            :
                            <iframe
                                width="100%"
                                height="100%"
                                src="https://www.youtube.com/embed/BnF3Y4S8OAg"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen={true}
                                rel="0"
                                showinfo="0"
                                controls="1">
                            </iframe>}
                    </div>
                    <div className="col-sm-12">
                        <div className="container-lg">
                            <div className="row">
                                <div className="col-12">
                                    <div className="title-design-2 mt-5"><p>{translate.t('maxPotencialDesign')}</p></div>
                                    <div className="texto-design-2">
                                        <p>{translate.t('maxPotencialText')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MobileView>
            </div>
        </div>
    )
}

function Objetives() {
    return (
        <div className="container-lg">
            <div className="row ">
                <div className="col-lg-6 col-sm-12 background-design">
                    <div className="row">
                        <div className="col-lg-7 col-sm-12 margin-left-objetives margin-mobile-left mt-3 pt-5">
                            <div className="title-design-2 title-design-2Mobile"><p>{translate.t('theObjetive')}<br />{translate.t('ofTheProposal')}</p></div>
                            <div className="texto-design-2 mt-5 mr-3 mb-5 texto-design-2Mobile">
                                <p>
                                    {translate.t('objetiveProposalText1')}
                                    <br />
                                    <br />
                                    {translate.t('objetiveProposalText2')}
                                    <br />
                                    <br />
                                    {translate.t('objetiveProposalText3')}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-12">&nbsp;</div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 no-padding-lateral back-interior-col"><img src={Interior} width="100%" height="100%" alt="Loading..." /></div>
            </div>
        </div>
    )
}

function Process() {
    return (
        <div>
            <BrowserView>
                <div className="mt-5 poss-relative">
                    <div className="linea-proceso d-none d-sm-block d-md-none d-lg-block"></div>
                    <p className="title-proceso">{translate.t('howDoes')}<br />{translate.t('theProcess')}</p>
                    <ModalSchedule />
                </div>

                <div className="container">
                    <div className="row mt-5 pt-5 justify-content-md-center">
                        <div className="col-lg-3 col-md-12 px-4 texto-proceso">
                            <div className="mb-4"><img src={number1} height={heightNumbers} alt="Loading..." /></div>
                            <p><strong>{translate.t('processTextStrong1')}</strong> {translate.t('processText1')}</p>
                        </div>
                        <div className="col-lg-3 col-md-12 px-4 texto-proceso">
                            <div className="mb-4"><img src={number2} height={heightNumbers} alt="Loading..." /></div>
                            <p><strong>{translate.t('processTextStrong2')}</strong> {translate.t('processText2')}</p>
                        </div>
                        <div className="col-lg-3 col-md-12 px-4 texto-proceso">
                            <div className="mb-4"><img src={number3} height={heightNumbers} alt="Loading..." /></div>
                            <p><strong>{translate.t('processTextStrong3')}</strong>{translate.t('processText3')}</p>
                        </div>
                        <div className="col-lg-3 col-md-12 px-4 texto-proceso">
                            <div className="mb-4"><img src={number4} height={heightNumbers} alt="Loading..." /></div>
                            <p><strong>{translate.t('processTextStrong4')}</strong>{translate.t('processText4')}</p>
                        </div>
                    </div>
                </div>
            </BrowserView>

            <MobileView>
                <div className="mt-5 poss-relative">
                    <div className="linea-proceso d-none d-sm-block d-md-none d-lg-block"></div>
                    <p className="title-proceso">{translate.t('howDoes')}<br />{translate.t('theProcess')}</p>
                </div>

                <div className="carousel-design">
                    <div className="carousel-slide-design">
                        <div>
                            <div className="row">
                                <div className="col mt-4 ml-4 texto-proceso">
                                    <div className="mb-4 mt-4"><img src={number1} height={heightNumbers} alt="Loading..." /></div>
                                    <p><strong>{translate.t('processTextStrong1')}</strong> {translate.t('processText1')}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col mt-4 ml-4 mr-4 texto-proceso">
                                    <div className="mb-4 mt-4"><img src={number2} height={heightNumbers} alt="Loading..." /></div>
                                    <p><strong>{translate.t('processTextStrong2')}</strong> {translate.t('processText2')}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col mt-4 ml-4 mr-4 texto-proceso">
                                    <div className="mb-4 mt-4"><img src={number3} height={heightNumbers} alt="Loading..." /></div>
                                    <p><strong>{translate.t('processTextStrong3')}</strong>{translate.t('processText3')}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col mt-4 ml-4 texto-proceso">
                                    <div className="mb-4 mt-4"><img src={number4} height={heightNumbers} alt="Loading..." /></div>
                                    <p><strong>{translate.t('processTextStrong4')}</strong>{translate.t('processText4')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="align-center">
                    <ModalSchedule />
                </div>
            </MobileView>
        </div>
    )
}

export default ScreenDesign;