import './PropertySkeleton.scss';
import whiteHeart from '../../assets/icons/heart-b.png'
// import '../PropertyBlock/PropertyBlockV2.scss';
export const Skeleton = () => {
  return (
    <div className="skeleton">
      <div className="fav-icon-skeleton-container" style={{width:"100%"}}>
        <img src={whiteHeart} className="fav-icon-skeleton"/>
      </div>
      <div className="skeleton_load_extreme_descripion"></div>
      <div className="skeleton_load_extreme_title"></div>
      <div className="skeleton_load_extreme_title_2"></div>
      <div className="skeleton_load_extreme_title_3"></div>
    </div>
  );
};
