import React, { useState, useRef } from 'react';
import './SimpleSlider.scss';
import cardClean from '../../assets/9. PARTNERS/card-clean.jpg';
import browsing from '../../assets/browsing.jpg';
import bed from '../../assets/bed.jpg';
import woman from '../../assets/woman.jpg';

const SimpleSlider = ({
  items,
  stretch = false,
  dots = false,
  centered = false,
  shadow = false,
  noMargin = false,
  className = '',
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef(null);
  const startPointRef = useRef(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + items.length) % items.length,
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    startPointRef.current = event.clientX || event.touches[0].clientX;
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const currentX = event.clientX || event.touches[0].clientX;
      const deltaX = startPointRef.current - currentX;

      if (Math.abs(deltaX) > 100) {
        if (deltaX > 0) {
          nextSlide();
        } else {
          prevSlide();
        }
        startPointRef.current = currentX;
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className={className}>
      <div
        className={!stretch ? 'slider-container' : 'slider-stretch-container'}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
      >
        <div
          className={noMargin ? 'slider-no-margin' : 'slider'}
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          ref={sliderRef}
        >
          {items.map((item, index) => (
            <div key={index} className="slider-slide">
              <div
                className={`slider-card ${
                  centered && 'justify-content-center'
                }`}
              >
                {item.img && <img src={item.img} alt={`Slide ${index + 1}`} />}
                <h4 className={`title-content`}>{item.title}</h4>
                <span className="text-content">{item.text}</span>
              </div>
            </div>
          ))}
        </div>
        {dots && (
          <div className="slider-navigation">
            <div className="dots">
              {items.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${index === currentIndex ? 'active' : ''}`}
                  onClick={() => goToSlide(index)}
                ></span>
              ))}
            </div>
          </div>
        )}
      </div>
      {!dots && (
        <div className="slider-buttons">
          <button onClick={prevSlide}>
            <span
              className="material-symbols-outlined"
              style={{ fontSize: '50px' }}
            >
              chevron_left
            </span>
          </button>
          <button onClick={nextSlide}>
            <span
              className="material-symbols-outlined"
              style={{ fontSize: '50px' }}
            >
              chevron_right
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default SimpleSlider;
