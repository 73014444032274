import React from 'react';
import '../components/componentStyles/TermsStyle.css';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';

function TermsScreen() {
    return(
        <div>
            <NavBar />

            <Terms />

            <Footer />
        </div>
    )
}

export default TermsScreen;


function Terms(){
    return(
        <div className="container-lg">
            <div className="container">
                <div className="text-terms margins-terms">
                    <p><strong>CONDICIONES PARA LA RESERVA</strong>
                        <br />
                        <br />
                        Es obligatorio la mayoría de edad de la persona que realiza la reserva y nos debe garantizar ser financieramente responsable del pago de los servicios contratados a Welcome2BA.
                        El pedido de reserva lo inicia a través de nuestro website completando el formulario de solicitud de reserva, vía mail o por teléfono comunicándose a nuestras oficinas.
                        <br />
                        Debe contactarse con nosotros con al menos 72 horas de anticipación a la fecha de check in para confirmar su pedido.
                        Nos comprometemos a responder su consulta dentro de las 48hs de efectuada para informarle sobre la disponibilidad, tarifas y otros datos de importancia.
                        <br />
                        Para que una reserva quede confirmada, es necesario el pago del 20% del valor total de la estadía, que se puede efectuar por transferencia bancaria, utilizando servicios internacionales de envío de dinero o con su tarjeta de crédito, o mismo en la oficina en dinero en efectivo (dólares o su equivalente en pesos o euros).
                        El resto del pago de la locación se efectúa en efectivo el día de check in en la propiedad conjunta a la firma del contrato.
                        <br />
                        <br />
                        Depósito:
                        <br />
                        <br />
                        El Huésped deberá abonar conjunto al valor del alquiler un depósito en garantía cuyo valor varía según plazo de alquiler y tipo de departamento. El depósito deberá ser abonado en efectivo (dólares o su equivalente en euros o pesos) y será devuelto el día de check out previa revisión del inventario firmado anexo al contrato. De existir daños por desgaste o mal uso de las comodidades del departamento el Huésped deberá afrontar dichos gastos de acuerdo a los valores establecidos en el inventario.
                        Los montos establecidos para el pago del deposito serán de la siguiente manera:
                        <br />
                        <br />
                        a) Menos 1 mes: El equivalente a 1 semana de alquiler
                        <br />
                        b) Un mes o más: El equivalente a 2 semanas de alquiler</p>

                        <div className="linea-card-terms"></div>

                    <p><strong>POLÍTICAS Y CONDICIONES PARTICULARES</strong>
                        <br />
                        <br />
                        Es nuestro principal objetivo brindarle la máxima satisfacción al turista ofreciéndole respuestas rápidas y eficientes ante cualquier inquietud o inconveniente que pueda suscitarse durante los 365 días del año las 24hs del día. Un miembro de nuestro Staff dará la bienvenida al Huésped en el departamento el día de su llegada, asistiéndolo durante toda su estadía y despidiéndolo en el momento de su partida.
                        El horario de check in establecido es a las 14:00hs pudiendo ser adelantado si ese mismo día el departamento no está alquilado a otro huésped. El horario de check out se estipula a las 10:00hs pudiendo ser modificado según la necesidad del Huésped si el departamento no está alquilado para ese mismo día. Será necesario saber con antelación los nombres y edades de todas las personas que habitarán la propiedad. De permanecer en el inmueble otros huéspedes que no figuraban previamente al contrato se considerará como motivo de desalojo. Los propietarios y Welcome2BA se reservan el derecho de admisión.
                        El huésped deberá reportar a la brevedad cualquier tipo de inconveniente o desperfecto en cuanto a las comodidades del departamento y sus instalaciones para que Welcome2BA pueda responder en forma rápida y eficaz en pos de resolver el problema.
                        Los montos de los alquileres incluyen todos los servicios e impuestos del inmueble como ser: Expensas, Impuestos, Luz, Gas, Agua, y dependiendo del departamento el servicio de Teléfono, Televisión por Cable y Servicio de Mucama con la frecuencia indicada en cada departamento.
                        El total de la estadía y el depósito en garantía se abonan al momento de su llegada al departamento.
                        <br />
                        <br />
                        Todos los departamentos publicados en Welcome2BA han sido previamente seleccionados por nuestro personal y todos cuentan con el mobiliario correspondiente y se encuentran equipados con vajilla, utensilios de cocina y ropa blanca.
                        La mayoría de los departamentos que se publican en este sitio pueden ser reservados para estadías mínimas de 1 semana salvo excepciones en donde se permiten estadías inferiores que serán publicadas en la página. La estadía máxima será de 6 meses (acorde a la legislación de alquileres temporarios) con la posibilidad de renovar por otros 6 meses más.
                        Welcome2BA es encargado de llevar a cabo todo tipo de tramitación para la reserva y ocupación de un determinado inmueble por el periodo solicitado. De surgir algún imprevisto que imposibilite la ocupación de dicha propiedad en las fechas solicitadas, Welcome2BA le ofrecerá otras alternativas de departamentos de similares características y comodidades, disponibles para la fecha de su viaje.
                        El día y horario de llegada del huésped previamente acordado, un representante de Welcome2BA lo recibirá en la propiedad rentada donde procederá a mostrarle las distintas instalaciones y su uso, le comentará políticas de funcionamiento del edificio y lo asesorará respecto a la zona de la propiedad acerca de los medios de transporte, lugares turísticos, bares, restaurantes, parques y demás. En ese momento se llevará a cabo la firma del contrato con el correspondiente inventario adjunto, el pago del monto total de alquiler más el depósito en garantía correspondiente y se entregarán las llaves al huésped. Además debe ser acordado en ese momento el horario de salida del departamento según las necesidades del huésped (check out). Si la propiedad está alquilada a otro huésped el mismo día del check out, éste deberá realizarse como máximo a las 10:00 para que pueda ser levada a cabo la tarea de limpieza del mismo para la nueva entrega.
                        Los departamentos deben ser devueltos en las mismas condiciones de orden y limpieza en la que fueron presentados al huésped.
                        El día de check out del departamento se retirarán las copias de las llaves de la propiedad que fueron entregadas en el check in y se le retornará al huésped el depósito en garantía entregado anteriormente previa revisión del inventario y control general del estado del departamento.</p>

                        <div className="linea-card-terms"></div>

                    <p><strong>CANCELACIÓN DE LA RESERVA</strong>
                        <br />
                        <br />
                        En caso de que el huésped desee cancelar la reserva efectuada no le será reintegrado el importe abonado, como así tampoco 
                        se le devolverá el dinero abonado en caso de que deseara acortar su estadía ya que es considerado compensación por incumplimiento de contrato.</p>

                        <div className="linea-card-terms"></div>

                    <p><strong>EXTENSIÓN DE LA ESTADÍA</strong>
                        <br />
                        <br />
                        Los contratos de alquiler temporario no pueden ser prorrogados, los mismos tienen una fecha de inicio y una fecha de finalización. En caso de que el huésped desee prolongar su estadía en la propiedad alquilada, deberá comunicarlo con la mayor antelación posible a
                        Welcome2BA para asegurarse que la propiedad no sea alquilada a otro posible huésped. La extensión misma obligará a la confección de un nuevo contrato de alquiler por el periodo deseado, sujeta a variaciones que puedan producirse en la tarifa.</p>

                        <div className="linea-card-terms"></div>

                    <p><strong>CONFORMIDAD A LAS NORMAS</strong>
                        <br />
                        <br />
                        Welcome2ba no se responsabiliza ante cualquier inconveniente que surja luego de firmar el contrato y entregar la 
                        propiedad en optimas condiciones, pero si brinda un servicio de asistencia para solucionar entre el inquilino y el propietario 
                        cualquier inconveniente que pueda ocurrir en la propiedad. Mediante la utilización de nuestro sitio y servicios, usted presta 
                        conformidad y queda sujeto a todos los Términos y Condiciones, Política de Privacidad y condiciones de Reserva y Políticas 
                        de Cancelación. Si usted no estuviese de acuerdo con las mismas, por favor, absténgase de contratar nuestros servicios.</p>
                </div>
            </div>
        </div>
    )
}