import API from './API';
import Helper from './Helper';
import { Property } from './Interfaces';
import { IProperty } from './interfaces/hostfully.property.interface';

export const getElementsOfLocalStorage = (local: string) => {
  let propsInLocalStorage =
    localStorage.getItem(local) !== null
      ? JSON.parse(localStorage.getItem(local) || '')
      : localStorage.setItem(local, JSON.stringify([]));
  return propsInLocalStorage;
};

export const handleFavoriteProp = (prop: IProperty) => {
  let propsInLocalStorage = getElementsOfLocalStorage('props');
  savePropToLocalStorage(prop, propsInLocalStorage);
};

export const getAllFavoritesProperties = async () => {
  let propsInLocalStorage = getElementsOfLocalStorage('props');
  console.log('propsInLocalStorage', propsInLocalStorage);
  let promises: Promise<string>[] = [];
  let favorites;
  let resolvedPromises;
  if (propsInLocalStorage.length > 0) {
    propsInLocalStorage.map((propId: string) => {
      promises.push(API.Web.getPropertyById({}, propId));
      promises.push(API.Web.getPropertyDescription({ propertyUid: propId }));
    });
  }
  resolvedPromises = await Promise.all(promises);
  favorites = Helper.parsePropertyListWithDescription(resolvedPromises);

  return favorites;
};

export const savePropToLocalStorage = (prop: IProperty, local: string[]) => {
  if (!isPropInFavorites(prop.uid)) {
    local.push(prop.uid);
    localStorage.setItem('props', JSON.stringify(local));
  } else {
    let filtered = local.filter((property: string) => property !== prop.uid);
    localStorage.setItem('props', JSON.stringify(filtered));
  }
};

export const isPropInFavorites = (uid: string): boolean => {
  let propsInLocalStorage = getElementsOfLocalStorage('props');
  if (propsInLocalStorage)
    return propsInLocalStorage.filter((prop: string) => prop === uid).length > 0
      ? true
      : false;

  return false;
};
