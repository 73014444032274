import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_URI;
const API_ENDPOINT_V1 = process.env.REACT_APP_API_URI_V1;
const header = { 'X-HOSTFULLY-APIKEY': 'acnnkSWougy1JVLE' };

axios.interceptors.request.use(
  function (config) {
    config.metadata = { startTime: new Date() };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    response.config.metadata.endTime = new Date();
    response.duration =
      response.config.metadata.endTime - response.config.metadata.startTime;
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default class AuthService {
  getPure(url, params = false) {
    var response = axios
      .get(url, { params: params, headers: header })
      .then((responseJson) => {
        return responseJson.data;
      })
      .catch((error) => {
        console.error('get helper error ' + error);
      });
    return response;
  }

  get(url, params = false, v3 = false) {
    // console.log('Auth.get', url, params);

    url = v3 ? 'https://api.hostfully.com/api/v3/' : API_ENDPOINT + url;
    params.agencyUid = '064c3db9-f703-4609-8afc-24917b91f906';
    var response = axios
      .get(url, { params: params, headers: header })
      .then((responseJson) => {
        if (responseJson.duration != undefined) {
          responseJson.data.duration = responseJson.duration;
        }

        return responseJson.data;
      })
      .catch((error) => {
        let data = [];
        if (error.response) {
          data.errorResponse = error.response.data;
          data.errorStatus = error.response.status;
        }
        return data;
      });
    return response;
  }

  postPure(url, params = false) {
    var response = axios
      .post(url, { params: params, headers: header })
      .then((responseJson) => {
        if (responseJson.duration != undefined) {
          responseJson.data.duration = responseJson.duration;
        }
        return responseJson.data;
      })
      .catch((error) => {
        console.error('post helper error ' + error);
      });
    return response;
  }

  delete(url, params = false) {
    var response = axios
      .delete(url, { params: params, headers: header })
      .then((responseJson) => {
        if (responseJson.duration != undefined) {
          responseJson.data.duration = responseJson.duration;
        }
        return responseJson.data;
      })
      .catch((error) => {
        console.error('post helper error ' + error);
      });
    return response;
  }

  postRegular(url, params = false) {
    var response = axios
      .post(url, params, { headers: header })
      .then((responseJson) => {
        if (responseJson.duration != undefined) {
          responseJson.data.duration = responseJson.duration;
        }
        return responseJson.data;
      })
      .catch((error) => {
        console.error('post helper error ' + error);
      });
    return response;
  }

  post(url, params = false) {
    url = API_ENDPOINT + url;
    params.agencyUid = '064c3db9-f703-4609-8afc-24917b91f906';
    var response = axios
      .post(url, params, { headers: header })
      .then((responseJson) => {
        if (responseJson.duration != undefined) {
          responseJson.data.duration = responseJson.duration;
        }
        return responseJson.data;
      })
      .catch((error) => {
        console.error('post helper error ' + error);
      });
    return response;
  }

  postV1(url, params = false) {
    url = API_ENDPOINT_V1 + url;
    params.agencyUid = '064c3db9-f703-4609-8afc-24917b91f906';

    var response = axios
      .post(url, params, { headers: header })
      .then((responseJson) => {
        if (responseJson.duration != undefined) {
          responseJson.data.duration = responseJson.duration;
        }
        return responseJson.data;
      })
      .catch((error) => {
        console.error('post helper error ' + error);
      });
    return response;
  }

  getV1(url, params = false) {
    url = API_ENDPOINT_V1 + url;
    params.agencyUid = '064c3db9-f703-4609-8afc-24917b91f906';

    //axios.con

    //axios.get(url,header)
    var response = axios
      .get(url, { params: params, headers: header })
      .then((responseJson) => {
        if (responseJson.duration != undefined) {
          responseJson.data.duration = responseJson.duration;
        }
        return responseJson.data;
      })
      .catch((error) => {
        console.error('post helper error ' + error);
      });
    return response;
  }
}

const API_WELCOME_ENDPOINT = process.env.REACT_APP_API_WELCOME;
const HEADER_WELCOME = { 'X-HOSTFULLY-APIKEY': 'acnnkSWougy1JVLE' };
class AuthWelcome {
  get(url, params: any) {
    url = API_WELCOME_ENDPOINT + url;
    console.log('url to send API WELCOME', API_WELCOME_ENDPOINT, url);
    params.agencyUid = '064c3db9-f703-4609-8afc-24917b91f906';
    var response = axios
      .get(url, { params: params, headers: HEADER_WELCOME })
      .then((responseJson) => {
        return responseJson.data;
      })
      .catch((error) => {
        let data = [];
        if (error.response) {
          data.errorResponse = error.response.data;
          data.errorStatus = error.response.status;
        }
        return data;
      });
    return response;
  }

  post(url, params = false) {
    console.log('trying to post', params);
    url = API_WELCOME_ENDPOINT + url;
    params.agencyUid = '064c3db9-f703-4609-8afc-24917b91f906';
    var response = axios
      .post(url, params, { headers: header })
      .then((responseJson) => {
        return responseJson.data;
      })
      .catch((error) => {
        console.error('post helper error ' + error);
      });
    return response;
  }
}

export { AuthWelcome };
