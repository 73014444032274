import carolina from '../../assets/2. HISTORIA/carolina.jpg';
import gabriel from '../../assets/2. HISTORIA/gabriel.jpg';
import andrea from '../../assets/2. HISTORIA/andrea.jpg';
import jacqueline from '../../assets/2. HISTORIA/jacqueline.png';
import marcos from '../../assets/2. HISTORIA/Marcos.png';
import mariana from '../../assets/2. HISTORIA/mariana.png';
import eliana from '../../assets/2. HISTORIA/Eliana.png';
import camila from '../../assets/2. HISTORIA/Camila.png';
import evangelina from '../../assets/2. HISTORIA/evangelina.png';
import valentina from '../../assets/2. HISTORIA/valentina.png';
import sbourie from '../../assets/2. HISTORIA/sbourie.png';
import sbazan from '../../assets/2. HISTORIA/sbazan.png';
import maria from '../../assets/2. HISTORIA/maria.png';
import logo from '../../assets/10. NOSOTROS/logolined.svg';
import Carousel from 'react-multi-carousel';
import { isMobile } from 'react-device-detect';
import 'react-multi-carousel/lib/styles.css';

const Team = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 664 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 867, min: 0 },
      items: 1,

      partialVisibilityGutter: 50, // Ajusta esto según tu preferencia
    },
  };

  const teamList = [
    {
      image: gabriel,
      name: 'Gabriel Bourie',
      charge: 'Co-founder & CEO',
    },
    {
      image: carolina,
      name: 'Carolina Bourie',
      charge: 'Co-founder & VP',
    },
    {
      image: mariana,
      name: 'Mariana Kosiner',
      charge: 'Chief Operating officer',
    },
    {
      image: eliana,
      name: 'Eliana Monaco',
      charge: 'Chief Interior Designer',
    },
    {
      image: sbourie,
      name: 'Sofia Bourie',
      charge: 'Executive Housekeeper',
    },
    {
      image: marcos,
      name: 'Marcos Cohen',
      charge: 'Guest Specialist',
    },
    {
      image: andrea,
      name: 'Andrea Leal',
      charge: 'Guest Specialist',
    },
    {
      image: jacqueline,
      name: 'Jacqueline Irala',
      charge: 'Administration Manager',
    },
    {
      image: camila,
      name: 'Camila Schivo',
      charge: 'Senior Interior Designer',
    },
    {
      image: valentina,
      name: 'Valentina Aguilar',
      charge: 'Interior Designer',
    },
    {
      image: maria,
      name: 'Maria Maciel',
      charge: 'Housekeeping Manager',
    },
    {
      image: sbazan,
      name: 'Sofia Bazan',
      charge: 'Housekeeping Manager',
    },
  ];

  return (
    <div>
      {!isMobile ? (
        <div className="team-container hide-in-mobile">
          <h4>Team Welcome2BA</h4>

          <div className="d-flex flex-wrap justify-content-center gap-3">
            {teamList.map((each) => (
              <MemberBlock member={each} />
            ))}
          </div>

          <img src={logo} className="team-logo" alt="logo" />
        </div>
      ) : null}

      {isMobile ? (
        <div className="team-slider">
          <h4>Team Welcome2BA</h4>
          <Carousel
            infinite={true}
            arrows={false}
            responsive={responsive}
            partialVisible={true}
            renderButtonGroupOutside={true}
            className="team-carousel"
            itemClass="team-item-carousel"
            showDots={true}
            dotListClass="team-dotlist"
          >
            {teamList.map((each) => (
              <MemberBlock member={each} />
            ))}
          </Carousel>
          <img src={logo} className="team-logo-slider" alt="logo" />
        </div>
      ) : null}
    </div>
  );
};

export default Team;

const MemberBlock = ({ member }) => {
  return (
    <div className="d-flex flex-column align-items-center mr-4 mt-2 mb-2">
      <img
        alt={`${member.name} - ${member.charge}`}
        src={member.image}
        width={200}
        height={200}
        className="rounded-circle"
      />
      <span>{member.name}</span>
      <p>{member.charge}</p>
    </div>
  );
};
