import { useEffect, useState } from 'react';
import { PropertyService } from '../services/property.service';
import { TNeighborhoodListResponse } from '../filter/FilterContainer';

export default function useNeighborhoods() {
  const [neighboorList, setNeighboors] = useState<TNeighborhoodListResponse>();
  const [loadingNeighborhoods, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    PropertyService.getNeighborhoods().then((response) => {
      setNeighboors(response);
    });
  }, []);

  return { loadingNeighborhoods, neighboorList };
}
