import { useState } from 'react';
import ImageGallery from 'react-image-gallery';

import translate from '../components/languages';
import { useLocation } from 'react-router-dom';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { HELPER_TSX } from './HelperTSX';
import { GalleryAjax } from './images/Gallery';

function LoadingContent() {
  return (
    <div className="loading-container">
      <div className="spinner-border color-green" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

function MyGallery({ images = [], showThumbnails = true }) {
  return (
    <ImageGallery
      items={images}
      showThumbnails={showThumbnails}
      lazyLoad={true}
      showNav={true}
      showPlayButton={false}
    />
  );
}

function MiniPropertyBlock({
  eachProperty,
  map = false,
  cantDaysToBook = false,
  miniCard = false,
}) {
  const location = useLocation();
  const [filters, setFilters] = useState(HELPER_TSX.getRecentParams(location));
  let priceToShow = 'test price';

  const title = eachProperty?.descriptions.length
    ? eachProperty?.descriptions[0]?.name
    : eachProperty.name;

  return (
    <div
      className={`${
        !map
          ? 'col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12'
          : 'col-sm-12 map-content-property'
      }`}
      style={{ borderRadius: '8px 8px 0px 0px' }}
    >
      <div className="card-home">
        {!map ? (
          <GalleryAjax property={eachProperty} />
        ) : (
          <a
            target="_BLANK"
            href={HELPER_TSX.prepareRouteCheckout(eachProperty, filters)}
            rel="noreferrer"
          >
            <div>
              <img
                src={eachProperty.pictureLink}
                width="100%"
                alt="Loading..."
              />
            </div>
          </a>
        )}

        <div className="pl-1 pr-1 pt-1 " alt={title} title={title}>
          <a
            target="_BLANK"
            href={HELPER_TSX.prepareRouteCheckout(eachProperty, filters)}
            rel="noreferrer"
          >
            <p className="title-name-home truncate mb-1" title={title}>
              {title}
            </p>
          </a>
        </div>

        <span className="city-home">{eachProperty.address.city}</span>
        <div className="linea-card-home"></div>
        <span className="type-text-home">
          <strong>{translate.t('guestCard')}</strong>{' '}
          {eachProperty?.availability.baseGuests} •
          <strong> {translate.t('beds')}</strong> {eachProperty?.bedrooms} •
          <strong> {translate.t('mts')}</strong> {eachProperty?.area.size} m²
        </span>

        {!miniCard && (
          <div className="containerBtnViajeros">
            <a
              target="_BLANK"
              href={HELPER_TSX.prepareRouteCheckout(eachProperty, filters)}
              rel="noreferrer"
            >
              <button type="button" className="button-price-viajero">
                <div className="row">
                  <span className="from-precio-prop mr-1">
                    {translate.t('rentalFrom')}
                  </span>
                  <span className="currency-precio-prop mr-1">
                    {eachProperty?.currency}{' '}
                  </span>
                  <span className="rate-precio-prop mr-1">{priceToShow}</span>
                </div>
              </button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export { MyGallery, LoadingContent, MiniPropertyBlock };
