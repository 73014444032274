import AuthService from './AuthService';
import Helper, { Variables } from './Helper';
const Auth = new AuthService();

const API_PAYMENT_URL = process.env.REACT_APP_API_PAYMENT_API ? process.env.REACT_APP_API_PAYMENT_API : `http://144.126.209.73:81`;
const API_PAYMENT_GET_TOKEN = 'client_token';
const API_PAYMENT_POST_CHECKOUT = 'checkout';

export const API = {
  addMinutes: function (date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  },
  restMinutes: function (date, minutes) {
    return new Date(date.getTime() - minutes * 60000);
  },
  Web: {
    getProperties: function (params) { return Auth.get('properties', params) },
    getPropertiesCount: async function (params) {
      params.limit = Variables.limitMax;
      return Auth.get('properties', params).then(response => {
        return response?.propertiesUids?.length;
      })
    },

    getPropertiesList: async function (params) {
      return this.getProperties(params).then(propertieIds => {
        let promises = [];
        propertieIds.propertiesUids != undefined && propertieIds.propertiesUids.map(eachPropertyId => {
          promises.push(API.Web.getPropertyById({}, eachPropertyId));
          promises.push(API.Web.getPropertyDescription({ propertyUid: eachPropertyId }));
        });
        return Promise.all(promises);
      })
    },
    getBookQuote: function (params, uid) { return Auth.get(`quote/${uid}`, params) },
    getPropertyById: function (params, uid) { return Auth.get(`properties/${uid}`, params) },
    getPropertyBedTypes: function (params) { return Auth.get(`bedtypes`, params) },
    getPropertyV1ById: function (uid) { return Auth.getV1(`properties/${uid}`, []) },
    getPropertyDescription: function (params) { return Auth.get(`propertydescriptions`, params) },
    getPropertyCustomAmenities: function (params) { return Auth.get(`customamenities`, params) },
    getAgencies: function (params) { return Auth.get('agencies', params) },
    getToken: function () { return Auth.getPure(API_PAYMENT_URL + API_PAYMENT_GET_TOKEN) },
    getPhotosById: function (params) { return Auth.get('photos', params) },
    getPhotosV3: function (params) { return Auth.get('photos', params, true) },
    getAmenities: function (uid) { return Auth.get(`amenities/${uid}`, []) },
    postCustomAmenitie: function (params) { return Auth.postRegular(`https://api.hostfully.com/v2/customamenities`, params) },
    deleteCustomAmenitie: function (params, uId) { return Auth.delete(`https://api.hostfully.com/v2/customamenities/${uId}`, params) },
    postLead: function (params) { return Auth.postV1('leads', params) },
    postPayment: function (payload, rentData) {
      return Auth.postPure(API_PAYMENT_URL + API_PAYMENT_POST_CHECKOUT, { 'paymentMethodNonce': payload.nonce, 'rentData': rentData });
    },
    removeBatchCustomAmenities: function (propUid = false) {

      if (propUid) {
        API.Web.cleanCustomAmenities(propUid);
        console.log(`deleting id ${propUid}`);
        return;
      }

      API.Web.getProperties({ limit: 999 }).then(properties => {

        (async () => {
          for (const uId of properties.propertiesUids) {
            await API.Web.cleanCustomAmenities(uId);
            await this.sleep(500);

            console.log('delete each ', uId);
            // debugger;
          }
          console.log('termina delete');
        })();
      });
    },
    sleep: function (ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    addBatchCustomAmenities: function () {
      API.Web.getProperties({ limit: 999 }).then(properties => {

        (async () => {
          for (const uId of properties.propertiesUids) {
            // debugger;
            await API.Web.addCustomAmenitiesByAmenities(uId);
            await this.sleep(1000);
          }
        })();

        /*
    
            properties.propertiesUids.map(async uId => {
              
              setTimeout( async () => {
                console.log('update custom amenitiies id',uId);
                await API.Web.addCustomAmenitiesByAmenities(uId);
              }, 5000);
              
    
            });
            */
      });
    },
    addCustomAmenitiesByAmenities: async function (propertyId) {
      await API.Web.getAmenities(propertyId).then(async amenities => {
        let amenitiesActived = Helper.filterTrueAmenities(Object.entries(amenities));


        (async () => {
          for (const each of amenitiesActived) {
            if (each[0] != undefined) {
              await this.sleep(1000);
              console.log('amenitie de property add ' + each[0] + ' ' + propertyId);
              await API.Web.postCustomAmenitie({ "propertyUid": propertyId, "name": each[0] });
            }
          }
        })();

      })
    },
    getAllCustomAmenitiesByPropCount: function () {
      API.Web.getProperties({ limit: 999 }).then(properties => {

        (async () => {
          for (const uId of properties.propertiesUids) {
            API.Web.getPropertyCustomAmenities({ "propertyUid": uId }).then(amenities => {
              console.log(`cant of custom amenities of ${uId}:`, amenities.length);
            });
          }
        })();


      });
    },
    cleanCustomAmenities: async function (propertyId) {
      //reco 360 hardcoded name: "https://kuula.co/share/collection/7YYnR?fs=1&vr=0&zoom=1&sd=1&thumbs=1&info=1&logo=1"

      API.Web.getPropertyCustomAmenities({ "propertyUid": propertyId }).then(amenities => {

        (async () => {
          console.log('cant of custom amenities', amenities.length);
          if (amenities.length > 1) {
            for (const each of amenities) {
              if (each.name.length < 30 && each.uid) {
                await this.sleep(2000);
                await API.Web.deleteCustomAmenitie({ "propertyUid": propertyId }, each.uid);
              }
            }
          }
        })();


      });
    },
    postSlackMessage: function (data) {
      var data = JSON.stringify(data);
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;

      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          console.log(this.responseText);
        }
      });
      xhr.open("POST", "https://hooks.slack.com/services/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");
      xhr.send(data);
    },
    postLeadV1: function (data) {
      Auth.postPure(API_PAYMENT_URL + 'web-api/postLead', data);
    }
  }
};

const HELPER = {

};


export default API;
export { HELPER };
