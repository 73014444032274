import translate from '../../components/languages';
import aboutUsWallpaper from '../../assets/10. NOSOTROS/banner_quienes_somos_new.jpg';
import icon1 from '../../assets/10. NOSOTROS/design.svg';
import icon2 from '../../assets/10. NOSOTROS/freedom.svg';
import icon3 from '../../assets/10. NOSOTROS/belonging.svg';
import icon4 from '../../assets/10. NOSOTROS/innovation.svg';

const Header = () => {
  return (
    <div className="">
      <div className="history-header-container">
        <div className="us-header-text">
          <div className="us-text">
            <h4>{translate.t('usTitle')}</h4>
            <span>{translate.t('usSubTitle')}</span>
          </div>
        </div>
        <div className="us-header-pic">
          <img src={aboutUsWallpaper} />
        </div>
      </div>

      <div className="history-values-container">
        <div className="history-values-row">
          <br />
          <br />
          <span className="history-phrase-dots">● ● ●</span>
          <h5>Nos regimos por 4 valores esenciales</h5>
          <br className="hide-in-mobile" />
          <br />
          <div className="history-icons-container">
            <div className="history-icon-container">
              <img src={icon1} />
            </div>
            <div className="history-icon-container">
              <img src={icon2} />
            </div>
            <div className="history-icon-container">
              <img src={icon3} />
            </div>
            <div className="history-icon-container">
              <img src={icon4} />
            </div>
          </div>
        </div>

        <div className="history-texts-container">
          <div className="history-text">
            Fusionando hospitalidad de excelencia con un diseño innovador y
            tecnología avanzada, creamos espacios inspiradores que promueven el
            bienestar.
          </div>
          <div className="history-text-1">
            Nos comprometemos con una excelencia operativa meticulosa,
            incorporando la eficiencia desde servicios automatizados hasta una
            atención personalizada. Cada detalle está diseñado para transformar
            cada visita en una experiencia más allá de una simple estadía:{' '}
            <strong style={{ fontFamily: 'SharpBold', color: '#008880' }}>
              un recuerdo inolvidable.
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
