import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import header from '../../assets/12. WORK TRIPS/header.png';
import img1 from '../../assets/12. WORK TRIPS/img1.png';
import img2 from '../../assets/12. WORK TRIPS/img2.png';
import img3 from '../../assets/12. WORK TRIPS/img3.png';
import setup from '../../assets/12. WORK TRIPS/setup.svg';
import nespresso from '../../assets/12. WORK TRIPS/nespresso.png';
import twentyfour from '../../assets/12. WORK TRIPS/twentyfour.png';
import wifi from '../../assets/12. WORK TRIPS/wifi.png';
import check from '../../assets/12. WORK TRIPS/check.svg';
import mark1 from '../../assets/12. WORK TRIPS/mark1.svg';
import mark2 from '../../assets/12. WORK TRIPS/mark2.svg';
import mark3 from '../../assets/12. WORK TRIPS/mark3.svg';
import mark4 from '../../assets/12. WORK TRIPS/mark4.svg';
import flower from '../../assets/icons/flower.svg';
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';
import translate from '../../components/languages';
import './WorkTrips.scss';
import { BrowserView, MobileView } from 'react-device-detect';

import AliceCarousel from 'react-alice-carousel';
import API from '../../utils/API';
import { useRef, useState } from 'react';
import moment from 'moment';
import { Variables } from '../../utils/Helper';
import { PickerHandle } from 'rsuite';

const { beforeToday } = DateRangePicker;

const responsive = {
  0: { items: 1 },
  1280: { items: 3 },
  1400: { items: 3 },
};

const WorkTrips = () => {
  const [name, setName] = useState('');
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [lastName, setLastName] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [business, setBusiness] = useState('');
  const [guests, setGuests] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [sendTxtBtn, setSendTxt] = useState(
    translate.t('workTripsObject.btnFormToSend'),
  );

  const [selectedRange, setSelectedRange] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined]);
  const refRangePicker = useRef<PickerHandle | null>(null);

  const sendData = () => {
    // const data = {
    //   name,
    //   lastName,
    //   phone,
    //   email,
    //   message,
    //   checkIn,
    //   checkOut,
    //   guests,
    //   business,
    //   neighborhood,
    // };

    // console.log('checker data', data);

    let str = `Nuevo contacto por una Business Trip \n`;
    str += `nombre:${name} \n`;
    str += `apellido:${lastName} \n`;
    str += `tel:${phone} \n`;
    str += `email:${email} \n`;
    str += `mensaje:${message} \n`;
    str += `check in:${checkIn} \n`;
    str += `check out:${checkOut} \n`;
    str += `barrio:${neighborhood} \n`;
    str += `cant huepsedes:${guests} \n`;
    str += `empresa:${business} \n`;
    API.Web.postSlackMessage({ text: str });
    setSendTxt(translate.t('workTripsObject.btnFormSent'));
  };

  const handleSelect = (newDate: Date) => {
    if (selectedRange[0] !== undefined && selectedRange[1] === undefined) {
      let dayDiff = moment(newDate).diff(moment(selectedRange[0]), 'days');
      if (dayDiff <= 0) {
        alert(translate.t('alertCheckoutWrongDate').toString());
        return;
      }

      setSelectedRange([selectedRange[0], newDate]);
      const dates = {
        checkInDate: moment(selectedRange[0]).format(
          Variables.hostfullyDateFormat,
        ),
        checkOutDate: moment(newDate).format(Variables.hostfullyDateFormat),
      };
      setCheckIn(
        moment(selectedRange[0]).format(Variables.hostfullyDateFormat),
      );
      setCheckOut(moment(newDate).format(Variables.hostfullyDateFormat));

      console.log('aca', dates);

      setTimeout(() => {
        const button = $('.rs-btn-primary');
        button.trigger('click');
      }, 500);
    } else {
      setSelectedRange([newDate, undefined]);
    }
  };

  console.log('checkin', checkIn);

  const pickerValue: DateRange | null = selectedRange.length
    ? [moment(selectedRange[0]).toDate(), moment(selectedRange[1]).toDate()]
    : null;

  const datePickerPlaceHolder = `${translate.t(
    'labelDateFrom',
  )} - ${translate.t('labelDateTo')} `;
  return (
    <div className="wt-content">
      <NavBar altLogo={true} />
      <div className="navbar-background"></div>
      <div className="wt-header-container">
        <div className="wt-header-texts">
          <h3>{translate.t('workTripsObject.weThinkTitle').toString()}</h3>
          <span>{translate.t('workTripsObject.weThink').toString()}</span>
        </div>
        <div className="wt-header-img">
          <img src={header} alt="header business tripe" />
        </div>
      </div>

      <div className="wt-body-container">
        <div className="wt-body-mobile-background"></div>
        <MobileView style={{ marginBottom: '20px' }}>
          <span
            className="wt-dots"
            style={{
              fontSize: '15px',
              color: '#009984',
              marginBottom: '30px',
              marginLeft: '20px',
            }}
          >
            ● ● ●
          </span>
        </MobileView>
        <h3> {translate.t('workTripsObject.comercialTxt').toString()}</h3>
        <div className="wt-data-container">
          <BrowserView>
            <div className="wt-imgs-container">
              <img src={img1} alt="working trips1" />
              <img src={img2} alt="working trips2" />
              <img src={img3} alt="working trips3" />
            </div>
          </BrowserView>
          <MobileView>
            <div className="wt-test">
              {/* [<img src={img1} />, <img src={img2} />, <img src={img3} />] */}
              <AliceCarousel
                items={[
                  <img src={img1} className="wt-img-carousel" />,
                  <img
                    src={img2}
                    alt="working trips carousel"
                    className="wt-img-carousel"
                  />,
                  <img
                    src={img3}
                    className="wt-img-carousel"
                    alt="working trips carousel 2"
                  />,
                ]}
                responsive={responsive}
                controlsStrategy="alternate"
                animationType="fadeout"
              />
            </div>
          </MobileView>
          <BrowserView>
            <div className="wt-icons-container">
              <div className="wt-icon">
                <img src={setup} />
                <span>
                  {translate.t('workTripsObject.features.feature1').toString()}
                </span>
              </div>
              <div className="wt-icon">
                <img src={nespresso} />
                <span>
                  {translate.t('workTripsObject.features.feature2').toString()}
                </span>
              </div>
              <div className="wt-icon">
                <img src={twentyfour} />
                <span>
                  {translate.t('workTripsObject.features.feature3').toString()}
                </span>
              </div>
              <div className="wt-icon">
                <img src={wifi} />
                <span>
                  {translate.t('workTripsObject.features.feature4').toString()}
                </span>
              </div>
            </div>
          </BrowserView>
          <MobileView>
            <div className="wt-icons-container">
              <div className="wt-icon">
                <img src={setup} />
                <span>
                  {translate.t('workTripsObject.features.feature1').toString()}
                </span>
              </div>
              <div className="wt-icon">
                <img src={nespresso} />
                <span>
                  {translate.t('workTripsObject.features.feature2').toString()}
                </span>
              </div>
              <div className="wt-icon">
                <img src={wifi} />
                <span>
                  {translate.t('workTripsObject.features.feature3').toString()}
                </span>
              </div>
              <div className="wt-icon">
                <img src={twentyfour} />
                <span>
                  {translate.t('workTripsObject.features.feature4').toString()}
                </span>
              </div>
            </div>
          </MobileView>
        </div>
        <div className="wt-contact-container">
          <div className="wt-conctact-text">
            <BrowserView style={{ marginBottom: '20px' }}>
              <span
                className="wt-dots"
                style={{
                  fontSize: '10px',
                  color: '#009984',
                  marginBottom: '30px',
                }}
              >
                ● ●
              </span>
            </BrowserView>
            <MobileView style={{ marginBottom: '0px' }}>
              <span
                className="wt-dots"
                style={{
                  fontSize: '10px',
                  color: '#009984',
                  marginBottom: '0px',
                  marginLeft: '20px',
                }}
              >
                ● ●
              </span>
            </MobileView>
            <h3>{translate.t('workTripsObject.contactUs').toString()}</h3>
            <span className="wt-contact-text">
              {translate.t('workTripsObject.contactSource').toString()}{' '}
              <strong>reservas@welcome2ba.com</strong>{' '}
              {translate.t('workTripsObject.contactOtherWay').toString()}
            </span>
            <div className="wt-contact-tip-container">
              <div className="wt-contact-tip">
                <img src={check} />
                <span>
                  {translate.t('workTripsObject.tips.tip1').toString()}
                </span>
              </div>
              <div className="wt-contact-tip">
                <img src={check} />
                <span>
                  {translate.t('workTripsObject.tips.tip2').toString()}
                </span>
              </div>
              <div className="wt-contact-tip">
                <img src={check} />
                <span>
                  {translate.t('workTripsObject.tips.tip3').toString()}
                </span>
              </div>
              <div className="wt-contact-tip">
                <img src={check} />
                <span>
                  {translate.t('workTripsObject.tips.tip4').toString()}
                </span>
              </div>
            </div>
          </div>
          <div className="wt-contact-form">
            <BrowserView>
              <div className="wt-contact-overlap-form">
                <div className="wt-input-pair">
                  <input
                    placeholder="Nombre *"
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    placeholder="Apellido *"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className="wt-input-pair">
                  <input
                    placeholder="Email *"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    placeholder="Mobile *"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="w-100">
                  <input
                    placeholder="Empresa *"
                    style={{ width: '100%' }}
                    onChange={(e) => setBusiness(e.target.value)}
                  />
                </div>
                <div className="wt-input-pair">
                  <input
                    placeholder="Barrio de interés *"
                    onChange={(e) => setNeighborhood(e.target.value)}
                  />
                  <input
                    placeholder="Cantidad de huéspedes *"
                    onChange={(e) => setGuests(e.target.value)}
                  />
                </div>
                <div className="wt-input-pair">
                  <DateRangePicker
                    ref={refRangePicker}
                    shouldDisableDate={beforeToday()}
                    onSelect={handleSelect}
                    defaultValue={pickerValue}
                    placeholder={datePickerPlaceHolder}
                    format="dd-MM-yyyy"
                    className="filter-my-date-range-picker-clean whiteDateRange mb-2"
                    menuClassName="test"
                    appearance="subtle"
                  />
                </div>
                <div className="wt-input-pair">
                  <span>
                    {translate.t('workTripsObject.tellUsTitle').toString()}
                    <br />
                    {translate.t('workTripsObject.tellUsTxt').toString()}
                  </span>
                </div>
                <div
                  className="wt-input-pair"
                  style={{ marginTop: '-30px', width: '100%' }}
                >
                  <textarea
                    placeholder=""
                    style={{ width: '100%' }}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="wt-disclaimer">
                  <span>
                    {translate
                      .t('workTripsObject.formTermsAccepted')
                      .toString()}
                    <span style={{ color: '#008780' }}>Welcome2BA</span>
                  </span>
                </div>
                <button onClick={() => sendData()} className="wt-send-consult">
                  {sendTxtBtn.toString()}
                </button>
              </div>
            </BrowserView>
            <MobileView>
              <div className="wt-contact-mobile">
                <input placeholder="Nombre *" />
                <input placeholder="Apellido *" />
                <input placeholder="Email *" />
                <input placeholder="Mobile *" />
                <input placeholder="Empresa *" />
                <input placeholder="Barrio de interés *" />
                <input placeholder="Cantidad de huéspedes *" />
                <div className="wt-mobile-dp">
                  <DateRangePicker
                    ref={refRangePicker}
                    shouldDisableDate={beforeToday()}
                    onSelect={handleSelect}
                    defaultValue={pickerValue}
                    placeholder={datePickerPlaceHolder}
                    format="dd-MM-yyyy"
                    className="filter-my-date-range-picker-clean whiteDateRange mb-2"
                    menuClassName="test"
                    appearance="subtle"
                  />
                </div>
                <span
                  style={{
                    fontFamily: 'Georgia',
                    lineHeight: 1.5,
                    fontSize: '3.2vw',
                  }}
                >
                  {translate.t('workTripsObject.tellUsTitle').toString()}
                  <br />
                  {translate.t('workTripsObject.tellUsTxt').toString()}
                </span>
                <textarea
                  className="wt-textarea-mobile"
                  placeholder=""
                  style={{ width: '100%', marginTop: '-21px' }}
                />
                <span style={{ fontSize: '12px' }}>
                  {translate.t('workTripsObject.formTermsAccepted').toString()}
                  <span style={{ color: '#008780' }}>Welcome2BA</span>
                </span>
                <button className="wt-send-consult">
                  {sendTxtBtn.toString()}
                </button>
              </div>
            </MobileView>
          </div>
        </div>
      </div>
      <div className="wt-brands-container">
        <img src={flower} className="wt-flower" />
        <h3>{`${translate.t('theyTrust')}`}</h3>
        <BrowserView>
          <div className="wt-brands-icons">
            <img src={mark1} />
            <img src={mark2} />
            <img src={mark3} />
            <img src={mark4} />
          </div>
        </BrowserView>
        <MobileView>
          <div className="mt-brands-mobile" style={{ marginBottom: '50px' }}>
            <img src={mark1} style={{ width: '27%' }} />
            <img src={mark2} style={{ width: '51%' }} />
          </div>
          <div className="mt-brands-mobile mt-4">
            <img src={mark3} style={{ width: '62%' }} />
            <img src={mark4} style={{ width: '25%' }} />
          </div>
        </MobileView>
      </div>
      <Footer />
    </div>
  );
};

export default WorkTrips;
