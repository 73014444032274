import React from 'react';
import { styled } from '@mui/system';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';

const CustomPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    color: '#33B29F',
  },
}));

export const PaginationMUI = ({ pagesToShow, totalProperties, handleChange, page, showPagination }) => {
  if (!showPagination || !page) return null;

  return (
    <div className="pagination-cont">
      <Stack spacing={2}>
        <CustomPagination
          count={pagesToShow}
          shape="rounded"
          onChange={handleChange}
          page={page}
          color="primary"
          variant="outlined"
        />
      </Stack>
    </div>
  );
};