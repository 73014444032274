import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import '../components/componentStyles/ModalStyle.css';
import translate from '../components/languages';
import API from '../utils/API';

function ModalContact({ show, setOffModalParent }) {
  const [modalShow, setModalShow] = useState(false);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    setModalShow(show);
  }, [show]);

  function sendData() {
    let str = `Nuevo contacto en la web \n`;
    str += `nombre:${name} \n`;
    str += `apellido:${lastName} \n`;
    str += `tel:${phone} \n`;
    str += `email:${email} \n`;
    str += `mensaje:${message} \n`;
    API.Web.postSlackMessage({ text: str });
    setShowSuccessMessage(true);
  }

  const handleCloseModal = () => {
    setModalShow(false);
    setOffModalParent();
  };

  return (
    <>
      <Modal
        show={modalShow}
        size="md"
        onHide={() => handleCloseModal()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="title-modal"
          >
            {translate.t('contactUs')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5 className="text-modal">{translate.t('personalInformation')}</h5>
            <Form>
              <Row>
                <Col>
                  <input
                    className="forms-modal"
                    placeholder={translate.t('name')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Col>
                <Col>
                  <input
                    className="forms-modal"
                    placeholder={translate.t('lastName')}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <input
                    className="forms-modal"
                    placeholder={translate.t('phone')}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <input
                    className="forms-modal"
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <textarea
                    className="forms-modal"
                    placeholder={translate.t('message')}
                    onChange={(e) => setMessage(e.target.value)}
                  >
                    {message}
                  </textarea>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
        <br />
        <Modal.Footer>
          {showSuccessMessage ? (
            <button
              onClick={() => setModalShow(false)}
              className="button-modal"
            >
              {translate.t('modalContactThanks')}
            </button>
          ) : (
            <button onClick={() => sendData()} className="button-modal">
              {translate.t('modalContactSend')}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalContact;
