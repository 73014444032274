import { Carousel } from 'react-responsive-carousel';
import noimageFoundColored from './../../assets/no-apt-found.jpg';
import { HELPER_TSX } from '../HelperTSX';
import { IProperty } from '../interfaces/hostfully.property.interface';
import { useState } from 'react';

//Slick slider
import Slider from 'react-slick';
import { LazyLoadTypes } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export type TGalleryAjax = {
  property: IProperty;
  maxPicsByProp?: number;
};

const GalleryAjax = ({ property, maxPicsByProp = 4 }: TGalleryAjax) => {
  const settings = {
    className: 'w2-custom-slider',
    dots: false,
    lazyLoad: 'ondemand' as LazyLoadTypes,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const photos = property.photos.slice(0, maxPicsByProp);

  //   console.log('each property', property);

  if (photos && photos.length > 0) {
    return (
      <div className="slider-container">
        <Slider {...settings}>
          {photos.map((pic, i) => {
            return (
              <div key={property.uid + i} style={{ cursor: 'pointer' }}>
                <img src={pic.mediumScaleImageUrl} alt="property images" />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  } else {
    return <NotFoundImage />;
  }
};

const NotFoundImage = () => {
  return (
    <div className="gallery-container">
      <img
        src={noimageFoundColored}
        alt="not found"
        style={{ width: '100%' }}
      />
    </div>
  );
};

export { GalleryAjax, NotFoundImage };
