import FilterBox from '../FilterContainer';
import './FilterBoxMobile.scss';
const FilterBoxMobile = ({ setIsOpen, isOpen, sendToSearch }) => {
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="filterbox-btn-container">
      <button
        className={
          !isOpen
            ? 'filterbox-mobile-open d-flex align-items-center justify-content-between'
            : ''
        }
        onClick={toggleMenu}
      >
        {!isOpen && (
          <span className="material-symbols-outlined me-auto">search</span>
        )}

        {isOpen ? (
          <span className="filterbox-btn-close mx-auto">x</span>
        ) : (
          <span className="mx-auto">Comenzar búsqueda</span>
        )}
      </button>
      {isOpen ? (
        <FilterBox
          sendFilters={sendToSearch}
          showClearFilter={false}
          cleanFilter={true}
          forHome={true}
          forVideoBanner={false}
        />
      ) : null}
    </div>
  );
};

export default FilterBoxMobile;
