import API_WELCOME from '../API_WELCOME';
import {
  TNeighborhoodListResponse,
  TRecentParams,
} from '../filter/FilterContainer';
import {
  IProperty,
  TPropertyPhoto,
} from '../interfaces/hostfully.property.interface';

export const PropertyService = {
  getProperties: async function (params: TRecentParams) {
    const properties = await API_WELCOME.getProperties(params);
    return properties as { total: number; rows: IProperty[] };
  },
  getNeighborhoods: async function () {
    const neighboors = await API_WELCOME.getNeighborhoods();
    return neighboors as TNeighborhoodListResponse;
  },
  getPropertyPhotos: async function (propertyId: string) {
    const properties = await API_WELCOME.getPropertyPhotos(propertyId);
    return properties as { total: number; photos: TPropertyPhoto[] };
  },
};
