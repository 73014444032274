export const AMENITIES_LIST = {
  HAS_LAUNDRY: 'HAS_LAUNDRY',
  HAS_SHARED_WASHER: 'HAS_SHARED_WASHER',
  HAS_BARBECUE: 'HAS_BARBECUE',
  HAS_COFFEE_MAKER: 'HAS_COFFEE_MAKER',
  HAS_HANGERS: 'HAS_HANGERS',
  HAS_DECK_PATIO: 'HAS_DECK_PATIO',
  HAS_CROCKERY_CUTLERY: 'HAS_CROCKERY_CUTLERY',
  HAS_GYM: 'HAS_GYM',
  HAS_CONTACTLESS_CHECKIN: 'HAS_CONTACTLESS_CHECKIN',
  HAS_HEATED_POOL: 'HAS_HEATED_POOL',
  HAS_SMART_TV: 'HAS_SMART_TV',
  HAS_WATER_KETTLE: 'HAS_WATER_KETTLE',
  HAS_KITCHEN: 'HAS_KITCHEN',
  HAS_24_CHECKIN: 'HAS_24_CHECKIN',
  HAS_MASSAGE_ROOM: 'HAS_MASSAGE_ROOM',
  HAS_FRIDGE: 'HAS_FRIDGE',
  HAS_POOL: 'HAS_POOL',
  HAS_GARDEN: 'HAS_GARDEN',
  HAS_HEATING: 'HAS_HEATING',
  HAS_OVEN: 'HAS_OVEN',
  HAS_INTERNET_WIFI: 'HAS_INTERNET_WIFI',
  HAS_AIR_CONDITIONING: 'HAS_AIR_CONDITIONING',
  HAS_TV: 'HAS_TV',
  HAS_HAIR_DRYER: 'HAS_HAIR_DRYER',
  HAS_JACUZZI: 'HAS_JACUZZI',
  HAS_WASHER: 'HAS_WASHER',
  HAS_LAUNDROMAT_NEARBY: 'HAS_LAUNDROMAT_NEARBY',
};

export const AMENITIES_NAMES = {
  HAS_LAUNDRY: 'Laundry',
  HAS_SHARED_WASHER: 'Lavadora',
  HAS_BARBECUE: 'Parrilla',
  HAS_COFFEE_MAKER: 'Cafetera',
  HAS_HANGERS: 'Perchas',
  HAS_DECK_PATIO: 'Deck',
  HAS_CROCKERY_CUTLERY: 'Vajilla',
  HAS_GYM: 'Gym',
  HAS_CONTACTLESS_CHECKIN: 'Auto checkin',
  HAS_HEATED_POOL: 'Pileta climatizada',
  HAS_SMART_TV: 'TV',
  HAS_WATER_KETTLE: 'Calentador de agua',
  HAS_KITCHEN: 'kitchen',
  HAS_24_CHECKIN: '24hs check in',
  HAS_MASSAGE_ROOM: 'Sala de masajes',
  HAS_FRIDGE: 'Freezer',
  HAS_POOL: 'Piscina',
  HAS_GARDEN: 'Jardín',
  HAS_HEATING: 'Calefacción',
  HAS_OVEN: 'Horno',
  HAS_INTERNET_WIFI: 'Wifi',
  HAS_AIR_CONDITIONING: 'Aire Acondicionado',
  HAS_TV: 'TV',
  HAS_HAIR_DRYER: 'Secador de pelo',
  HAS_JACUZZI: 'Jacuzzi',
  HAS_WASHER: 'Lavadora',
  HAS_LAUNDROMAT_NEARBY: 'Laundry',
};

export enum TAemnities {
  KITCHEN = 'kitchen',
  GENERAL = 'general',
  INFRA = 'infrastructure',
  BATH = 'bathroom',
}

export const AMENITIES_FULL = [
  {
    amenity_en: 'has garden',
    amenity_es: 'jardín',
    amenity: 'HAS_GARDEN',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has buzzer',
    amenity_es: 'timbre',
    amenity: 'HAS_BUZZER',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'check in option instruction',
    amenity_es: 'instrucción de opción de check-in',
    amenity: 'CHECK_IN_OPTION_INSTRUCTION',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has mini fridge',
    amenity_es: 'mini nevera',
    amenity: 'HAS_MINI_FRIDGE',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'has smart tv',
    amenity_es: 'smart tv',
    amenity: 'HAS_SMART_TV',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has concierge',
    amenity_es: 'conserje',
    amenity: 'HAS_CONCIERGE',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has outdoor seating',
    amenity_es: 'asientos al aire libre',
    amenity: 'HAS_OUTDOOR_SEATING',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has cleaning products',
    amenity_es: 'productos de limpieza',
    amenity: 'HAS_CLEANING_PRODUCTS',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has indoor pool seasonal',
    amenity_es: 'piscina cubierta estacional',
    amenity: 'HAS_INDOOR_POOL_SEASONAL',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has oven',
    amenity_es: 'horno',
    amenity: 'HAS_OVEN',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'has hair dryer',
    amenity_es: 'secador de pelo',
    amenity: 'HAS_HAIR_DRYER',
    category: TAemnities.BATH,
  },
  {
    amenity_en: 'has extra pillows and blankets',
    amenity_es: 'almohadas y mantas adicionales',
    amenity: 'HAS_EXTRA_PILLOWS_AND_BLANKETS',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has heated pool',
    amenity_es: 'piscina climatizada',
    amenity: 'HAS_HEATED_POOL',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has wifi speed 100',
    amenity_es: 'wifi de alta velocidad',
    amenity: 'HAS_WIFI_SPEED_100',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has indoor fireplace',
    amenity_es: 'chimenea interior',
    amenity: 'HAS_INDOOR_FIREPLACE',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has smoke detector',
    amenity_es: 'detector de humo',
    amenity: 'HAS_SMOKE_DETECTOR',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has dishwasher',
    amenity_es: 'lavavajillas',
    amenity: 'HAS_DISHWASHER',
    category: TAemnities.KITCHEN,
  },
  // {
  //   amenity_en: 'check in option',
  //   amenity_es: 'opción de check-in',
  //   amenity: 'CHECK_IN_OPTION',
  //   category: TAemnities.GENERAL,
  // },
  {
    amenity_en: 'has safe',
    amenity_es: 'caja fuerte',
    amenity: 'HAS_SAFE',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has free parking',
    amenity_es: 'estacionamiento libre',
    amenity: 'HAS_FREE_PARKING',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has pool',
    amenity_es: 'piscina',
    amenity: 'HAS_POOL',
    category: TAemnities.INFRA,
  },
  // {
  //   amenity_en: 'has tv',
  //   amenity_es: 'tv',
  //   amenity: 'HAS_TV',
  //   category: TAemnities.GENERAL,
  // },
  {
    amenity_en: 'has window guards',
    amenity_es: 'guardias de ventana',
    amenity: 'HAS_WINDOW_GUARDS',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has conditioner',
    amenity_es: 'acondicionador',
    amenity: 'HAS_CONDITIONER',
    category: TAemnities.BATH,
  },
  {
    amenity_en: 'has surveillance',
    amenity_es: 'vigilancia',
    amenity: 'HAS_SURVEILLANCE',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has river',
    amenity_es: 'río',
    amenity: 'HAS_RIVER',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has heating',
    amenity_es: 'calefacción',
    amenity: 'HAS_HEATING',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'is laptop friendly',
    amenity_es: 'es amigable para portátiles',
    amenity: 'IS_LAPTOP_FRIENDLY',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has coffee',
    amenity_es: 'café',
    amenity: 'HAS_COFFEE',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'has safe box',
    amenity_es: 'caja de seguridad',
    amenity: 'HAS_SAFE_BOX',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has town',
    amenity_es: 'ciudad',
    amenity: 'HAS_TOWN',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has towels',
    amenity_es: 'toallas',
    amenity: 'HAS_TOWELS',
    category: TAemnities.BATH,
  },
  {
    amenity_en: 'has massage room',
    amenity_es: 'sala de masajes',
    amenity: 'HAS_MASSAGE_ROOM',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has meal delivery',
    amenity_es: 'entrega de comida',
    amenity: 'HAS_MEAL_DELIVERY',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has crockery cutlery',
    amenity_es: 'vajilla',
    amenity: 'HAS_CROCKERY_CUTLERY',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'is near ocean',
    amenity_es: 'está cerca del océano',
    amenity: 'IS_NEAR_OCEAN',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has pool all year',
    amenity_es: 'piscina todo el año',
    amenity: 'HAS_POOL_ALL_YEAR',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has alarm system',
    amenity_es: 'sistema de alarma',
    amenity: 'HAS_ALARM_SYSTEM',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has 24 checkin',
    amenity_es: '24 check-in',
    amenity: 'HAS_24_CHECKIN',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has wine glasses',
    amenity_es: 'copas de vino',
    amenity: 'HAS_WINE_GLASSES',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'has bidet',
    amenity_es: 'bidet',
    amenity: 'HAS_BIDET',
    category: TAemnities.BATH,
  },
  {
    amenity_en: 'has bathtub',
    amenity_es: 'bañera',
    amenity: 'HAS_BATHTUB',
    category: TAemnities.BATH,
  },
  {
    amenity_en: 'has pots pans',
    amenity_es: 'ollas y sartenes',
    amenity: 'HAS_POTS_PANS',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'has high touch surfaces cleaning with disinfectants',
    amenity_es: 'limpieza de superficies de alto contacto con desinfectantes',
    amenity: 'HAS_HIGH_TOUCH_SURFACES_CLEANING_WITH_DISINFECTANTS',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has barbecue',
    amenity_es: 'barbacoa',
    amenity: 'HAS_BARBECUE',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has fridge',
    amenity_es: 'nevera',
    amenity: 'HAS_FRIDGE',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'has communal pool',
    amenity_es: 'piscina comunitaria',
    amenity: 'HAS_COMMUNAL_POOL',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has wifi speed 50',
    amenity_es: 'velocidad wifi de 50 mbps',
    amenity: 'HAS_WIFI_SPEED_50',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has pool seasonal',
    amenity_es: 'piscina estacional',
    amenity: 'HAS_POOL_SEASONAL',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'is downtown',
    amenity_es: 'está en el centro',
    amenity: 'IS_DOWNTOWN',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has wifi speed 250',
    amenity_es: 'velocidad wifi de 250 mbps',
    amenity: 'HAS_WIFI_SPEED_250',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has single level',
    amenity_es: 'un solo nivel',
    amenity: 'HAS_SINGLE_LEVEL',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has wifi speed 500',
    amenity_es: 'velocidad wifi de 500 mbps',
    amenity: 'HAS_WIFI_SPEED_500',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has toaster',
    amenity_es: 'tostadora',
    amenity: 'HAS_TOASTER',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'has shared washer',
    amenity_es: 'lavadora compartida',
    amenity: 'HAS_SHARED_WASHER',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has paid on premises parking',
    amenity_es: 'estacionamiento pagado en las infrastructure',
    amenity: 'HAS_PAID_ON_PREMISES_PARKING',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has private entrance',
    amenity_es: 'entrada privada',
    amenity: 'HAS_PRIVATE_ENTRANCE',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has linens',
    amenity_es: 'sábanas',
    amenity: 'HAS_LINENS',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has cooking basics',
    amenity_es: 'lo básico para kitchenr',
    amenity: 'HAS_COOKING_BASICS',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'has jacuzzi',
    amenity_es: 'jacuzzi',
    amenity: 'HAS_JACUZZI',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has doorman',
    amenity_es: 'portero',
    amenity: 'HAS_DOORMAN',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has cleaning with disinfectants',
    amenity_es: 'limpieza con desinfectantes',
    amenity: 'HAS_CLEANING_WITH_DISINFECTANTS',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has contactless checkin',
    amenity_es: 'check-in sin contacto',
    amenity: 'HAS_CONTACTLESS_CHECKIN',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has wardrobe',
    amenity_es: 'armario',
    amenity: 'HAS_WARDROBE',
    category: 'Habitaciones',
  },
  {
    amenity_en: 'has cable tv',
    amenity_es: 'televisión por cable',
    amenity: 'HAS_CABLE_TV',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has stove',
    amenity_es: 'estufa',
    amenity: 'HAS_STOVE',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'has microwave oven',
    amenity_es: 'horno microondas',
    amenity: 'HAS_MICROWAVE_OVEN',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'has indoor pool',
    amenity_es: 'piscina cubierta',
    amenity: 'HAS_INDOOR_POOL',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'is wheelchair accessible',
    amenity_es: 'es accesible para silla de ruedas',
    amenity: 'IS_WHEELCHAIR_ACCESSIBLE',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has ceiling fan',
    amenity_es: 'ventilador de techo',
    amenity: 'HAS_CEILING_FAN',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has deck patio',
    amenity_es: 'terraza',
    amenity: 'HAS_DECK_PATIO',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has air conditioning',
    amenity_es: 'aire acondicionado',
    amenity: 'HAS_AIR_CONDITIONING',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has hot tub',
    amenity_es: 'bañera de hidromasaje',
    amenity: 'HAS_HOT_TUB',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has essentials',
    amenity_es: 'elementos esenciales',
    amenity: 'HAS_ESSENTIALS',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has desk',
    amenity_es: 'escritorio',
    amenity: 'HAS_DESK',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has water kettle',
    amenity_es: 'hervidor de agua',
    amenity: 'HAS_WATER_KETTLE',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'has free street parking',
    amenity_es: 'estacionamiento gratuito en la calle',
    amenity: 'HAS_FREE_STREET_PARKING',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has rain shower',
    amenity_es: 'ducha de lluvia',
    amenity: 'HAS_RAIN_SHOWER',
    category: TAemnities.BATH,
  },
  {
    amenity_en: 'has desk chair',
    amenity_es: 'silla de escritorio',
    amenity: 'HAS_DESK_CHAIR',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has shampoo',
    amenity_es: 'champú',
    amenity: 'HAS_SHAMPOO',
    category: TAemnities.BATH,
  },
  {
    amenity_en: 'has safety card',
    amenity_es: 'tarjeta de seguridad',
    amenity: 'HAS_SAFETY_CARD',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has clothes drying rack',
    amenity_es: 'tendedero',
    amenity: 'HAS_CLOTHES_DRYING_RACK',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has balcony terrasse',
    amenity_es: 'balcón/terraza',
    amenity: 'HAS_BALCONY_TERRASSE',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has washer',
    amenity_es: 'lavadora',
    amenity: 'HAS_WASHER',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has shared dryer',
    amenity_es: 'secadora compartida',
    amenity: 'HAS_SHARED_DRYER',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has iron',
    amenity_es: 'plancha',
    amenity: 'HAS_IRON',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has freezer',
    amenity_es: 'congelador',
    amenity: 'HAS_FREEZER',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'has hangers',
    amenity_es: 'perchas',
    amenity: 'HAS_HANGERS',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has dining table',
    amenity_es: 'mesa de comedor',
    amenity: 'HAS_DINING_TABLE',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has elevator',
    amenity_es: 'ascensor',
    amenity: 'HAS_ELEVATOR',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has gym',
    amenity_es: 'gimnasio',
    amenity: 'HAS_GYM',
    category: TAemnities.INFRA,
  },
  {
    amenity_en: 'has heating',
    amenity_es: 'calefacción',
    amenity: 'HAS_HEATING',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has coffeemaker',
    amenity_es: 'cafetera',
    amenity: 'HAS_COFFEEMAKER',
    category: TAemnities.KITCHEN,
  },
  {
    amenity_en: 'has fire extinguisher',
    amenity_es: 'extinguidor de incendios',
    amenity: 'HAS_FIRE_EXTINGUISHER',
    category: 'Seguridad',
  },
  {
    amenity_en: 'has smoke detector',
    amenity_es: 'detector de humo',
    amenity: 'HAS_SMOKE_DETECTOR',
    category: 'Seguridad',
  },
  {
    amenity_en: 'has carbon monoxide detector',
    amenity_es: 'detector de monóxido de carbono',
    amenity: 'HAS_CARBON_MONOXIDE_DETECTOR',
    category: 'Seguridad',
  },
  {
    amenity_en: 'allows pets',
    amenity_es: 'permite mascotas',
    amenity: 'ALLOWS_PETS',
    category: 'Políticas',
  },
  {
    amenity_en: 'has breakfast included',
    amenity_es: 'desayuno incluido',
    amenity: 'HAS_BREAKFAST_INCLUDED',
    category: 'Servicios',
  },
  {
    amenity_en: 'has crib',
    amenity_es: 'cuna',
    amenity: 'HAS_CRIB',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'has first aid kit',
    amenity_es: 'botiquín de primeros auxilios',
    amenity: 'HAS_FIRST_AID_KIT',
    category: 'Seguridad',
  },
  {
    amenity_en: 'has fireplace',
    amenity_es: 'chimenea',
    amenity: 'HAS_FIREPLACE',
    category: TAemnities.GENERAL,
  },
  {
    amenity_en: 'Allows smoking',
    amenity_es: 'permitido fumar',
    amenity: 'ALLOWS_SMOKING',
    category: 'Políticas',
  },
  {
    amenity_en: 'Allows events',
    amenity_es: 'se permiten eventos',
    amenity: 'ALLOWS_EVENTS',
    category: 'Políticas',
  },
];
