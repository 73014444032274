import partnerBanner from '../../assets/9. PARTNERS/parnters_new_back.jpg';
import './Partners.scss';
import Card from './Cards/Card';
// import leanDown from '../../assets/9. PARTNERS/leandown.jpg';
import leanDown from '../../assets/9. PARTNERS/central-photo.jpg';
import gestion from '../../assets/icons/integral.svg';
import manager from '../../assets/icons/channel_1.svg';
import prices from '../../assets/icons/prices.svg';
import reports from '../../assets/icons/chart.svg';
import flower from '../../assets/icons/flower.svg';
import linelogo from '../../assets/icons/linelogo.svg';
import { Slider } from './Slider';
import before from '../../assets/despues.png';
import after from '../../assets/antes.png';
import Toggle from './TogglesKnowMore/Toggle';
import Footer from '../../components/Footer';

import limpieza from '../../assets/9. PARTNERS/limpieza.png';
import sabana from '../../assets/9. PARTNERS/sabana.png';
import elegancia from '../../assets/9. PARTNERS/elegancia.png';
import detalles from '../../assets/9. PARTNERS/detalles-elevan.png';

import SimpleSlider from '../../utils/carousel/SimpleSlider';
import NavBar from '../../components/NavBar';
import translate from '../../components/languages';
import brochure from '../../assets/documents/brochure.pdf';
import { useState } from 'react';
import ModalContact from '../../components/ContactModal';

// TODO

const downloadBrocuher = () => {
  // Use the imported PDF file
  const link = document.createElement('a');
  link.href = brochure;
  link.setAttribute('download', 'welcome2ba-brochure.pdf'); // Set the file name for the download
  document.body.appendChild(link);
  link.click();
  link.remove(); // Clean up and remove the link
};
const Partners = () => {
  const [modalShow, setModalShow] = useState(false);

  const setOffModalParent = () => {
    setModalShow(false);
  };

  const items = [
    {
      img: limpieza,
      title: translate.t('partnerCard1Title'),
      text: translate.t('partnerCard1Content'),
    },
    {
      img: sabana,
      title: translate.t('partnerCard2Title'),
      text: translate.t('partnerCard2Content'),
    },
    {
      img: elegancia,
      title: translate.t('partnerCard3Title'),
      text: translate.t('partnerCard3Content'),
    },
    {
      img: detalles,
      title: translate.t('partnerCard4Title'),
      text: translate.t('partnerCard4Content'),
    },
  ];

  const benefits = [
    {
      title: translate.t('partnerBenefitsCard1Title'),
      text: translate.t('partnerBenefitsCard1Content'),
    },
    {
      title: translate.t('partnerBenefitsCard2Title'),
      text: translate.t('partnerBenefitsCard2Content'),
    },
    {
      title: translate.t('partnerBenefitsCard3Title'),
      text: translate.t('partnerBenefitsCard3Content'),
    },
    {
      title: translate.t('partnerBenefitsCard4Title'),
      text: translate.t('partnerBenefitsCard4Content'),
    },
  ];

  return (
    <div>
      <NavBar altLogo={true} />
      <div className="navbar-background"></div>
      <div className="banner-container">
        <div className="banner-content">
          <div className="col-md-5 d-flex align-items-center flex-end">
            <div className="banner-text-content">
              <h4>{translate.t('partnerTitle')}</h4>
              <span>{translate.t('partnerSubTitle')}</span>
            </div>
          </div>
          <div className="col-md-7 img-banner-1" style={{}}>
            <img src={partnerBanner} className="banner_pic" />
          </div>
        </div>
      </div>
      <div className="second-banner-container-background ">
        <div className="second-banner-container">
          <span className="phrase-dots-partners">● ● ●</span>
          <span className="quote-partners">
            {translate.t('partnerPhrase1')}
          </span>
          <span className="quote-partners">
            {translate.t('partnerPhrase2')}
          </span>
          <span className="quote-partners">
            <strong style={{ color: 'black' }}>
              {translate.t('partnerPhrase3-1')}
            </strong>
            {translate.t('partnerPhrase3-2')}
          </span>
        </div>
      </div>

      <div className="details-slide-container">
        <h4>{translate.t('partnerDetailsTitle')}</h4>
      </div>
      <SimpleSlider
        items={items}
        stretch={true}
        dots={true}
        className={'details-slider-container'}
      />
      <div className="cards-details-container ">
        <div className="d-flex flex-column">
          <h4 className="p-3 vanib">{translate.t('partnerDetailsTitle')}</h4>
          <div className="details-container-without-slider">
            <div className="col-md-3">
              <Card
                img={items[0].img}
                title={items[0].title}
                text={items[0].text}
              />
            </div>
            <div className="col-md-3">
              <Card
                img={items[1].img}
                title={items[1].title}
                text={items[1].text}
              />
            </div>
            <div className="col-md-3">
              <Card
                img={items[2].img}
                title={items[2].title}
                text={items[2].text}
              />
            </div>
            <div className="col-md-3">
              <Card
                img={items[3].img}
                title={items[3].title}
                text={items[3].text}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="details-container">
        <div className="col-md-6">
          <img src={leanDown} className="w-100" />
        </div>
        <div className="details-icons-container col-md-6">
          <div className="d-flex icon-pair">
            <div className="icons-container">
              <img src={gestion} />
              <span>{translate.t('partnerIcon1')}</span>
            </div>
            <div className="icons-container">
              <img src={manager} />
              <span>Channel Manager</span>
            </div>
          </div>
          <div className="d-flex icon-pair">
            <div className="icons-container">
              <img src={prices} />
              <span>{translate.t('partnerIcon3')}</span>
            </div>
            <div className="icons-container">
              <img src={reports} />
              <span>{translate.t('partnerIcon4')}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="details-container-mobile">
        <div className="details-icons-container col-md-6">
          <div className="d-flex icon-pair">
            <div className="icons-container col-sm-6">
              <img src={gestion} />
              <span>{translate.t('partnerIcon1')}</span>
            </div>
            <div className="icons-container col-sm-6">
              <img src={manager} />
              <span>Channel Manager</span>
            </div>
          </div>
          <div className="d-flex icon-pair">
            <div className="icons-container col-sm-6">
              <img src={prices} />
              <span>{translate.t('partnerIcon3')}</span>
            </div>
            <div className="icons-container col-sm-6">
              <img src={reports} />
              <span>{translate.t('partnerIcon4')}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="service-value-container-background">
        <div className="service-value-container">
          <img src={linelogo} className="line-logo" />
          <div className="start-now-banner">
            <div className="spans-desk col-md-8 m-auto text-left">
              <img src={flower} className="flower" />
              <h4>{translate.t('partnerServiceValueTitle')}</h4>
              <div className="spans">
                <div className="col-md-6 simple-fee">
                  <span>{translate.t('partnerSimpleFee')}</span>
                </div>
                <div className="col-md-6 discount flex-column">
                  <span style={{ fontSize: '20px' }}>
                    <strong style={{ fontFamily: 'SharpSemiBold20' }}>
                      20-25%
                    </strong>{' '}
                    {translate.t('partnerReservePerc')}
                  </span>
                  <span style={{ fontSize: '12px' }}>
                    {translate.t('partnerReservePercSub')}
                  </span>
                </div>
              </div>

              <p>{translate.t('partnerReserveNote')}</p>
            </div>
            <div className="spans-mobile-container">
              <img src={flower} className="flower" />
              <h4>{translate.t('partnerServiceValueTitle')}</h4>
              <div className="spans-mobile ">
                <div className="simple-fee">
                  <span>{translate.t('partnerSimpleFee')}</span>
                </div>
                <div className="discount">
                  <span>
                    <strong>20-25%</strong> {translate.t('partnerReservePerc')}
                  </span>
                  <span style={{ fontSize: '10px' }}>
                    {translate.t('partnerReservePercSub')}
                  </span>
                </div>
              </div>
              <div className="disc-text">
                <p>{translate.t('partnerReserveNote')}</p>
              </div>
            </div>
            <div
              className="col-md-4 btn-start-now-container"
              style={{ position: 'relative' }}
            >
              <button
                onClick={() => setModalShow(true)}
                className="start-now-btn"
              >
                {translate.t('partnerStartNowBtn')}
              </button>
              <ModalContact
                show={modalShow}
                setOffModalParent={setOffModalParent}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="benefits-container">
        <span className="phrase-dots-partners-2">● ● ●</span>
        <h4>{translate.t('partnerBenefitsTitle')}</h4>
        {/* <h4 style={{ marginTop: '-5px' }}>el valor de tu/s propiedad/es:</h4> */}
        <p className="benefits-sub">{translate.t('partnerBenefitsSubTitle')}</p>

        <div className="">
          <SimpleSlider
            items={benefits}
            stretch={true}
            centered={true}
            noMargin={true}
            shadow={true}
            className="slider-benefits"
          />

          <div className="benefits-card-container">
            {benefits.map((b) => (
              <div className="benefit-card">
                <div style={{ height: '34px', width: '102%' }}>
                  <h5>{b.title}</h5>
                </div>
                <div style={{ width: '100%' }}>
                  <p>{b.text}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="improve-design-container">
            <div className="col-md-9">
              <span className="spans">
                {translate.t('partnerBenefitsPhrase')}
              </span>

              <span
                style={{
                  color: '#009080',
                  cursor: 'pointer',
                  fontWeight: '300',
                }}
                onClick={() => downloadBrocuher()}
                className="brochure-link"
              >
                {' '}
                {translate.t('partnerBenefitsLink')}{' '}
                <span className="material-symbols-outlined">download</span>
              </span>
            </div>
            <div className="col-md-3" style={{ paddingTop: '27px' }}>
              <div className="btn-start-now-container p-0">
                <button
                  className="start-now-btn-2"
                  onClick={() => setModalShow(true)}
                  style={{
                    background: '#DD8700',
                    color: 'white',
                    width: '100%',
                  }}
                >
                  {translate.t('partnerStartNowBtn')}
                </button>
              </div>
            </div>
          </div>
          <div className="grey-line"></div>
        </div>
      </div>
      <div className="partners-slider-container">
        <div className="slider-title">
          <span>{translate.t('partnerChallengeCreative')}</span>
          <div className="vertical-line-separator"></div>
          <span>{translate.t('partnerChallengeCreative2')}</span>
        </div>
        <Slider
          // pointerMove
          beforeImage={after}
          afterImage={before}
        />
      </div>

      <div className="something-else-container">
        <h5>{translate.t('partnerWantKnowMoreTitle')}</h5>
        <div className="row mt-4">
          <div className="col-md-6 p-0">
            <Toggle
              title={translate.t('partnerReq1')}
              body={translate.t('partnerAnswer1')}
            />

            <Toggle
              title={translate.t('partnerReq2')}
              body={translate.t('partnerAnswer2')}
            />

            <Toggle
              title={translate.t('partnerReq3')}
              body={translate.t('partnerAnswer3')}
            />
          </div>
          <div className="col-md-6 p-0">
            <Toggle
              title={translate.t('partnerReq4')}
              body={translate.t('partnerAnswer4')}
            />

            <Toggle
              title={translate.t('partnerReq5')}
              body={translate.t('partnerAnswer5')}
            />

            <Toggle
              title={translate.t('partnerReq6')}
              body={translate.t('partnerAnswer6')}
            />
          </div>
          <div className="btn-start-now-container-2">
            <button
              onClick={() => setModalShow(true)}
              className="know-more-btn"
              style={{
                background: '#DD8700',
                color: 'white',
                fontSize: '19px',
              }}
            >
              {translate.t('seeMore')}
            </button>
          </div>
        </div>
      </div>

      <Footer color={'#D0D3DE'} />
    </div>
  );
};

export default Partners;
