import React, { useEffect, useRef, useState } from 'react';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import Helper from '../utils/Helper';
import markerw2ba from '../assets/marker-gmaps.svg';
import { renderToString } from 'react-dom/server';
import { MiniPropertyBlock } from '../utils/Utils';

const center = {
  lat: -3.745,
  lng: -38.523,
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

const LocationMap = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBKa2J2aZGcTsDbEI71tbVKCpj83N9V7ds',
  });

  const properties = props.properties;
  const cantDaysToBook = props.cantDaysToBook;

  const [markers, setMarkers] = useState(
    properties.map((property) => {
      const coordinates = Helper.prepareLocOwnServer(property);
      return {
        location: coordinates,
        info: property.name,
      };
    }),
  );

  const [activeProperty, setActiveProperty] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);

  const [map, setMap] = React.useState(null);
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((marker) => {
      bounds.extend(
        new window.google.maps.LatLng(
          marker.location.latitude,
          marker.location.longitude,
        ),
      );
    });
    map.fitBounds(bounds);

    // Set zoom level after fitting bounds
    window.google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
      console.log('bounds changed');

      if (props?.zoom) map.setZoom(props?.zoom);
    });

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const selectedProperty = (marker, indexProperty) => {
    setActiveMarker(marker);
    setActiveProperty(properties[indexProperty]);
  };

  const unSelectProperty = () => {
    setActiveMarker(null);
    setActiveProperty(null);
  };

  if (!isLoaded) return null;

  console.log('aca', activeProperty, cantDaysToBook);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={14}
      options={props?.mapOptions}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <ShowMarkers markers={markers} selectedProperty={selectedProperty} />

      {activeMarker && activeProperty && cantDaysToBook !== undefined && (
        <InfoWindow
          position={{
            lat: activeMarker.location.latitude,
            lng: activeMarker.location.longitude,
          }}
          onCloseClick={() => unSelectProperty()}
        >
          <MiniPropertyBlock
            map={true}
            cantDaysToBook={cantDaysToBook}
            eachProperty={activeProperty}
            miniCard={true}
          />
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

const ShowMarkers = ({ markers, selectedProperty }) => {
  const markerIcon = {
    url: markerw2ba,
    scaledSize: new window.google.maps.Size(80, 40),
  };

  return markers.map((marker, i) => {
    return (
      <Marker
        key={i}
        icon={markerIcon}
        position={{
          lat: marker.location.latitude,
          lng: marker.location.longitude,
        }}
        draggable={false}
        onClick={() => selectedProperty(marker, i)}
      />
    );
  });
};

export default LocationMap;
