import React from 'react';
import '../components/componentStyles/FaqStyle.css';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';

function FaqScreen() {
    return(
        <div>
            <NavBar />

            <Faqs />

            <Footer />
        </div>
    )
}

export default FaqScreen;


function Faqs(){
    return(
        <div className="container-lg">
            <div className="container">
                <div className="text-faq margins">
                    <p><strong>1. ¿Cuál es el período mínimo y máximo para rentar un apartamento?</strong>
                        <br />
                        La mayoría de los departamentos que se publican en este sitio pueden ser reservados para estadías mínimas de 1 semana.
                        Sin embargo determinados departamentos tienen un período mínimo mayor o menor, dependiendo del caso. La estadía máxima será de 6 meses, 
                        acorde a la legislación de aplicación vigente, con la posibilidad de renovar por otros 6 meses más. 
                        Sin embargo esta posibilidad está sujeta a disponibilidad.</p>

                        <div className="linea-card-faq"></div>

                    <p><strong>2. ¿Cuáles son las formas de pago aceptadas para confirmar la reserva?</strong>
                        <br />
                        El pago del 20% del total de su reserva en concepto de anticipo, se podrá abonar
                        a través de nuestro sistema de pagos con tarjeta de crédito de una forma rápida, sencilla, y segura. 
                        También podemos aceptar este importe a través de paypal, Western Union, o depósito bancario (únicamente en Argentina).</p>

                        <div className="linea-card-faq"></div>

                    <p><strong>3. ¿Cuáles son las formas de pago aceptadas para abonar el alquiler?</strong>
                        <br />
                        El pago del alquiler se abonará en el momento del ingreso, únicamente en efectivo en dólares y/o su equivalente en euros o pesos 
                        argentinos al tipo de cambio del día en que este se efectivice.</p>

                        <div className="linea-card-faq"></div>

                    <p><strong>4. ¿Puedo pagar todo el alquiler con tarjeta de crédito?</strong>
                        <br />
                        Por el momento Welcome2BA acepta el pago con tarjeta de crédito solamente para cancelar el 20% de la Reserva en concepto de anticipo. 
                        El resto deberá abonarse en efectivo, en el momento del ingreso.</p>

                        <div className="linea-card-faq"></div>

                    <p><strong>5. ¿Hay que dejar algún depósito en garantía al alquilar?</strong>
                        <br />
                        Usted debe abonar como depósito en garantía:
                        <br />
                        <br />
                        a) Menos 1 mes: El equivalente a 1 semana de alquiler
                        <br />
                        b) Un mes o más: El equivalente a 2 semanas de alquiler</p>

                        <div className="linea-card-faq"></div>
                        
                    <p><strong>6. ¿Hay cargos extras por gas, electricidad o agua?</strong>
                        <br />
                        Nuestros departamentos incluyen expensas y todos los servicios.</p>

                        <div className="linea-card-faq"></div>

                    <p><strong>7. ¿Hay sábanas y toallas en los departamentos?</strong>
                        <br />
                        Todos los departamentos que ofrecemos poseen toallas y sábanas. 
                        Algunos departamentos también cuentan con el servicio de limpieza semanal de ropa blanca para los alquileres mayores a una semana.</p>

                        <div className="linea-card-faq"></div>

                    <p><strong>8. ¿A qué hora puedo ingresar al apartamento?</strong>
                        <br />
                        Recibimos pasajeros los 365 días del año (Lunes a Viernes de 9 am a 21 pm incluyendo Fin de semana). 
                        Un representante de nuestro Staff le dará la bienvenida al huésped a su llegada a la hora previamente acordada entre las partes 
                        con un mínimo de 48 horas previas al día de Check IN. Sin embargo, U$S 15 serán cobrados al huésped en concepto de "Late Check -In", 
                        en caso de que este quisiera ingresar fuera del horario laboral (Lunes a Viernes de 21 pm a 9 am y Fin de Semana). Por una cuestión de seguridad, 
                        todos los ingresos que se realicen en la franja horaria de 21 pm a 9 am (Late Check –in) 
                        deberán contar con servicio de transporte brindado por nuestra empresa.</p>

                        <div className="linea-card-faq"></div>

                    <p><strong>9. ¿Qué ocurre si rompo algo?</strong>
                        <br />
                        Deberá pagar lo que rompa al precio que figura en el inventario que usted firmó cuando ingreso al departamento.</p>

                        <div className="linea-card-faq"></div>

                    <p><strong>10. ¿Qué pasa si mi vuelo se retrasa?</strong>
                        <br />
                        Al momento de confirmar su reserva, nuestro sistema le pedirá el número de vuelo. 
                        Este dato es fundamental para el caso de atraso de vuelo porque nos permite, en caso de que usted no se presente a la hora acordada, 
                        chequear en Internet el horario de su arribo. Con este nuevo horario, nosotros calculamos el tiempo necesario 
                        que le tomará realizar los trámites aduaneros y viajar hacia el departamento. Recomendamos tomar nota del 
                        número de emergencia que W2BA suministra cuando se envía la confirmación de su reserva, donde también podrá 
                        encontrar otros números para su contacto con nosotros.</p>

                        <div className="linea-card-faq"></div>

                    <p><strong>11. ¿Cuál es el tiempo de espera en el departamento?</strong>
                        <br />
                        Nuestro representante tiene orden de aguardar hasta 1 hora adicional a lo establecido en su confirmación de reserva. 
                        Después de este tiempo usted debe comunicarse a nuestros números comerciales.</p>

                        <div className="linea-card-faq"></div>

                    <p><strong>12. ¿Cómo debo proceder para reservar un departamento?</strong>
                        <br />
                        Cuando tenga elegido el departamento que desea reservar y este de acuerdo con el valor de renta a 
                        pagar una vez que haya realizado el cálculo con nuestro calculador de renta, puede realizar su reserva haciendo 
                        clic en el botón ¨RESERVAR¨, luego llena el formulario con sus datos y realiza el pago del 20% a través de su 
                        tarjeta de crédito, paypal, WU o depósito bancario (este último solo desde Argentina). Nosotros recibiremos su 
                        solicitud de reserva. La misma está sujeta a la aceptación del propietario. Nosotros contactaremos al propietario, 
                        y luego recibirá un e-mail con la dirección del departamento reservado y el horario de encuentro en el 
                        departamento para la entrega de las llaves.</p>

                        <div className="linea-card-faq"></div>

                    <p><strong>13. ¿Debo pagar algún gasto administrativo?</strong>
                        <br />
                        Sólo debe abonar US$45 de gastos administrativos junto con el anticipo del 20%.</p>
                </div>
            </div>
        </div>
    )
}