import { useState } from 'react';

export default function InputIncremental({ title, setData, value }) {
    let [count, setCount] = useState(value ? value : 0);

    function modifyCount(operation) {
        count = (operation === "+") ? count + 1 : count - 1;
        count = count < 0 ? 0 : count;
        setCount(count);
        setData(count);
    }

    return (
        <div className="inputIncremental mt-2 d-flex align-items-center col-xs-6">
            <h5 className='m-0' style={{width:'50px'}}>{title}</h5>
            <div className='d-flex align-items-center ml-4'>
                <button onClick={() => modifyCount('-')} className="bg-transparent">-</button>
                <span>{count}</span>
                <button onClick={() => modifyCount('+')} className="bg-transparent">+</button>
            </div>
        </div>
    );
}