const Card = ({title, text, img}) => {
    return <div>
        <div className="card-img-container">
            <img src={img} />
        </div>
        <div className="details-cards">
            <h4>{title}</h4>
            <p>{text}</p>
        </div>
    </div>
};

export default Card;