import translate from '../../components/languages';
import './MainBanner.scss';
import videoDesktop from '../../assets/videos/low-definition-desktop.mp4';
import videoMobile from '../../assets/videos/low-definition-mobile-3.mp4';
import bannerDesktop from '../../assets/banner1.jpg';
import bannerMobile from '../../assets/1. INICIO/static-banner-mobile.jpg';
import { isMobile } from 'react-device-detect';
import { useEffect, useRef, useState } from 'react';



const MainStaticBanner = (props) => { 
    const videoRef = useRef(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [enableFallbackImage, setEnableFallbackImage] = useState(false);
  
    useEffect(() => {
      
      const video = videoRef.current;
      if(video){
        // Check if autoplay is supported
        const playPromise = video.play();
    
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Autoplay started
              setIsVideoPlaying(true);
            })
            .catch(error => {
              // Autoplay was prevented
              // Show play button or other controls
              setEnableFallbackImage(true);
              console.log(`we encounter an error during autoplay video ${error}`)
            });
        }
      }
  
      return () => {
        // Cleanup
      };
    }, []);

    return (
        <div className='static-banner'>

            { enableFallbackImage ? 
              <img src={isMobile?bannerMobile:bannerDesktop} className="home-banner-fallback-image" alt="Welcome2BA" /> 
            :
              <video autoPlay playsInline muted loop ref={videoRef} id="myVideo" className="video-background">
                  <source src={isMobile?videoMobile:videoDesktop} type="video/mp4" />
              </video>
            }
            
            
            <div className="phrase-home content-overlay">
                <div style={{maxWidth:'1380px'}}>
                    <p style={{marginBottom:'0px'}}>{translate.t('homeSearchTitle1')}</p>
                    <p>{translate.t('homeSearchTitle2')}</p>
                </div>
            </div>
        </div>
    )
};

export default MainStaticBanner;
